import React, { useEffect, useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { HEPTemplate } from '@/common/types';
import { TemplateDetails } from '@/components/Health/HEPBuilder/steps/HepOrTemplateScreen/TemplateDetails/TemplateDetails';
import { TemplateSelectList } from '@/components/Health/HEPBuilder/steps/HepOrTemplateScreen/TemplateSelectList/TemplateSelectList';
import { WeightIcon } from '@/components/common/Icons/WeightIcon';
import { RadioCardGroup } from '@/components/common/RadioCardGroup/RadioCardGroup';
import { Button } from '@/components/common/button/button';
import { hepTemplatesState, selectedBuilderTemplateState } from '@/recoil/atoms/hepTemplatesAtom';
import { resultIsError } from '@/services/HttpService';
import { useHEPBuilderContext } from '../../state/hooks/HEPBuilderContext';
import {
    StepInformationWrapper,
    StepContentWrapper,
    ExerciseDetailsWrapper,
    ExerciseDetailsText,
    RadioCardGroupWrapper,
    CardButtonWrapper,
    CardButtonAvatar,
    CardButton,
    CardButtonText,
    CardButtonDescriptionText,
} from './styles';

interface CardProps {
    value: number;
    supTitle: string;
    title: string;
    cardDetails: JSX.Element;
}

const ExerciseDetails = ({ exercises }: { exercises: number }) => {
    return (
        <ExerciseDetailsWrapper>
            <WeightIcon />
            <ExerciseDetailsText variant="caption" sx={{ ml: 1 }}>
                {exercises} exercises
            </ExerciseDetailsText>
        </ExerciseDetailsWrapper>
    );
};

export const HepOrTemplateScreen = () => {
    const { patientRecord, handleNext } = useHEPBuilderContext();
    const theme = useTheme();
    const largeBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));
    const { httpService } = useHttpContext();
    const [hepTemplatesDict, setHepTemplatesDict] = useState<HEPTemplate[]>([]);
    const [hepTemplateCards, setHepTemplateCards] = useState<CardProps[]>([]);
    const setTemplatesDict = useSetRecoilState(hepTemplatesState);
    const [selectedTemplateId, setSelectedTemplateId] = useState<string | number>();
    const [selectedTemplate, setSelectedTemplate] = useRecoilState(selectedBuilderTemplateState);
    const { errorToast } = useToast();

    const convertToCards = (hepTemplates: HEPTemplate[]): CardProps[] => {
        return hepTemplates.map((hepTemplate) => {
            return {
                value: hepTemplate.id,
                supTitle: DateTime.fromISO(hepTemplate.dateUpdated).toFormat('MM/dd/yyyy'),
                title: hepTemplate.name,
                cardDetails: <ExerciseDetails exercises={hepTemplate.blocks.length} />,
            };
        });
    };

    const getHeps = async (): Promise<HEPTemplate[]> => {
        const res = await httpService.getHEPTemplates();
        if (resultIsError(res)) {
            errorToast('Could not get templates');
            return [];
        }
        setHepTemplatesDict(res);
        return res;
    };

    useEffect(() => {
        (async () => {
            const templateDict = await getHeps();
            if (templateDict && templateDict.length > 0) {
                setHepTemplateCards(convertToCards(templateDict));
                setTemplatesDict(templateDict);
                setSelectedTemplateId(templateDict[0].id);
            }
        })();
    }, []);

    useEffect(() => {
        const t = hepTemplatesDict.find((template) => template.id === Number(selectedTemplateId));
        if (t) {
            setSelectedTemplate(t);
        }
    }, [selectedTemplateId]);

    const handleNewHEPClick = () => {
        handleNext();
    };

    return (
        <StepInformationWrapper container columnSpacing={{ xs: 2, lg: 4, xl: 10 }}>
            <Grid container item sm={12} md={5} lg={6} xl={7}>
                <StepContentWrapper>
                    <Typography variant="body2" component="h1" fontWeight={700}>
                        Create a new Program for {patientRecord?.patient?.firstName} {patientRecord?.patient?.lastName}
                    </Typography>

                    <CardButtonWrapper elevation={0}>
                        <Button onClick={handleNewHEPClick} variant="card">
                            <CardButton elevation={0}>
                                <CardButtonAvatar>
                                    <AddRoundedIcon />
                                </CardButtonAvatar>
                                <CardButtonText variant="body2">New Program</CardButtonText>
                                <CardButtonDescriptionText>
                                    Create a new program for this patient from scratch or start from an existing
                                    template
                                </CardButtonDescriptionText>
                            </CardButton>
                        </Button>
                    </CardButtonWrapper>
                    {hepTemplateCards.length > 0 && largeBreakpoint && (
                        <RadioCardGroupWrapper>
                            <RadioCardGroup
                                data={hepTemplateCards}
                                selectedItem={setSelectedTemplateId}
                                formLabel="Or assign a Program directly from your template list"
                                defaultValue={selectedTemplateId}
                            />
                        </RadioCardGroupWrapper>
                    )}
                    {hepTemplatesDict.length > 0 && !largeBreakpoint && (
                        <TemplateSelectList data={hepTemplatesDict} selectedItem={setSelectedTemplateId} />
                    )}
                </StepContentWrapper>
            </Grid>
            <Grid item sm={12} md={7} lg={6} xl={5} style={{ overflowY: 'scroll' }}>
                {selectedTemplate && <TemplateDetails hepTemplate={selectedTemplate} />}
            </Grid>
        </StepInformationWrapper>
    );
};
