import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paths } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { Organization } from '@/common/types';
import { PatientProductBadges } from '@/components/Patients/ProductBadges/ProductBadges';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { Button } from '@/components/common/button/button';
import { resultIsError } from '@/services/HttpService';
import { OrganizationsTable } from './OrganizationsTable';

export const OrganizationsTableContainer = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<Organization[]>();
    const { httpService } = useHttpContext();
    const navigate = useNavigate();

    const getOrganizations = async () => {
        setIsLoading(true);
        const res = await httpService.getOrganizations();
        if (resultIsError(res)) {
        } else {
            const orderedByName = res.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            setRows(orderedByName);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await getOrganizations();
        })();
    }, []);

    const handleManageClick = (organizationId: number) => {
        navigate(`${Paths.adminOrg}${organizationId}`);
    };

    const columnStructure = [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ value }) => <strong>{value}</strong>,
        },
        {
            Header: 'TimeZone',
            accessor: 'timezone',
            Cell: ({ value }) => <strong>{value}</strong>,
        },
        {
            Header: 'Products',
            accessor: 'products',
            Cell: ({ value }) => {
                return <PatientProductBadges products={value} />;
            },
        },
        {
            Header: 'Manage',
            accessor: 'id',
            Cell: ({ value }) => {
                return (
                    <Button onClick={() => handleManageClick(value)} size="small" variant="secondary">
                        Manage
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                rows && <OrganizationsTable columnStructure={columnStructure} data={rows} />
            )}
        </div>
    );
};
