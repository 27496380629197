import React, { useEffect, useState } from 'react';
import { DndContext, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { Box, Typography } from '@mui/material';
import { Nullable, ScanModule, Assessment, SortableScanModule } from '@/common/types';
import { convertModulesToDTO } from '@/common/utils';
import { AddIcon } from '@/components/common/Icons/AddIcon';
import { Button } from '@/components/common/button/button';
import { AddAssessmentModal } from '../Assessments/AddAssessmentModal';
import { ModuleModal } from './ModuleModal';
import { SortableItem } from './SortableItem';

export const AdminModulesList = ({
    scanModules,
    assessmentsDict,
    submitScanModules,
}: {
    scanModules: SortableScanModule[];
    assessmentsDict: Assessment[];
    submitScanModules: (scanModules: ScanModule[]) => Promise<void>;
}) => {
    const [modules, setModules] = useState<SortableScanModule[]>(scanModules);
    const [backupModules, setBackupModules] = useState<SortableScanModule[]>(scanModules);
    const [openAddAssessment, setOpenAddAssessment] = useState(false);
    const [openAddModule, setOpenAddModule] = useState(false);
    const [currentModule, setCurrentModule] = useState<Nullable<SortableScanModule>>(null);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [isGrabbing, setIsGrabbing] = useState(false);

    useEffect(() => {
        setModules(scanModules);
        setBackupModules(scanModules);
    }, [scanModules]);

    const handleSaveScanModules = async () => {
        const convertedModules = convertModulesToDTO(modules);
        await submitScanModules(convertedModules);
        setHasUnsavedChanges(false);
    };

    const handleCancelChanges = () => {
        setModules(backupModules);
        setHasUnsavedChanges(false);
    };

    const handleToggleEditAssessmentModal = (module?: SortableScanModule) => {
        module && setCurrentModule(module);
        setOpenAddAssessment((prev) => !prev);
    };

    const handleSaveAssessment = (updatedModule: SortableScanModule) => {
        const updatedModules = modules.map((module) => (module.id === updatedModule.id ? updatedModule : module));
        setModules(updatedModules);
        setHasUnsavedChanges(true);
    };

    const handleDeleteAssessment = (module: SortableScanModule, assessmentId: string) => {
        const updatedAssessments = module.assessments.filter(
            (moduleAssessment) => moduleAssessment.id !== assessmentId,
        );
        const updatedModule = {
            ...module,
            assessments: updatedAssessments,
        };
        const updatedModules = modules.map((mod) => (mod.id === updatedModule.id ? { ...updatedModule } : mod));

        setModules(updatedModules);
        setHasUnsavedChanges(true);
    };

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        setIsGrabbing(false);

        if (over && active.id !== over?.id) {
            const oldIndex = modules.findIndex((module) => module.id === active.id);
            const newIndex = modules.findIndex((module) => module.id === over.id);
            setModules(arrayMove(modules, oldIndex, newIndex));
            setHasUnsavedChanges(true);
        }
    };

    const handleDragStart = (event: DragStartEvent) => {
        setIsGrabbing(true);
    };

    const handleSaveModule = (updatedModule: SortableScanModule, oldModuleName?: string) => {
        setModules((prevModules) => {
            return prevModules.map((module) => (module.id === oldModuleName ? updatedModule : module));
        });
        setHasUnsavedChanges(true);
    };

    const toggleModuleModal = () => {
        setOpenAddModule((prev) => !prev);
    };

    const handleAddNewModule = (newModule: SortableScanModule) => {
        setModules((prev) => [newModule, ...prev]);
        setHasUnsavedChanges(true);
    };

    const handleDeleteModule = (moduleId: string) => {
        const updatedModules = modules.filter((module) => module.id !== moduleId);
        setModules(updatedModules);
        setHasUnsavedChanges(true);
    };

    return (
        <div>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    marginBottom: '32px',
                }}
            >
                <Button onClick={toggleModuleModal} color="primary" startIcon={<AddIcon />}>
                    Add Module
                </Button>
            </Box>
            {modules && modules.length > 0 ? (
                <DndContext
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                    modifiers={[restrictToVerticalAxis]}
                >
                    <SortableContext items={modules.map((module) => module.id)}>
                        <Box
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                                rowGap: 16,
                                marginBottom: hasUnsavedChanges ? 140 : '',
                            }}
                        >
                            {modules.map((module) => (
                                <SortableItem
                                    key={module.id}
                                    module={module}
                                    setModules={setModules}
                                    handleSaveModule={handleSaveModule}
                                    handleAddAssessment={handleToggleEditAssessmentModal}
                                    handleSaveAssessment={handleSaveAssessment}
                                    handleDeleteAssessment={handleDeleteAssessment}
                                    handleDeleteModule={handleDeleteModule}
                                    setHasUnsavedChanges={setHasUnsavedChanges}
                                    isGrabbing={isGrabbing}
                                />
                            ))}
                        </Box>
                    </SortableContext>
                </DndContext>
            ) : (
                <Typography variant="h6" align="center">
                    This org has no modules. Please add a module.
                </Typography>
            )}

            <ModuleModal open={openAddModule} onClose={toggleModuleModal} onSave={handleAddNewModule} type="NEW" />

            <AddAssessmentModal
                open={openAddAssessment}
                onClose={handleToggleEditAssessmentModal}
                assessments={assessmentsDict}
                onSave={handleSaveAssessment}
                module={currentModule}
            />

            {hasUnsavedChanges && (
                <Box
                    style={{
                        backgroundColor: '#fff',
                        padding: '24px 24px',
                        position: 'fixed',
                        bottom: '40px',
                        right: '40px',
                        zIndex: 9999,
                        boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
                        borderRadius: 4,
                    }}
                >
                    <Button onClick={handleCancelChanges} variant="tertiary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveScanModules}
                        variant="primary"
                        style={{
                            marginLeft: 24,
                        }}
                    >
                        Save
                    </Button>
                </Box>
            )}
        </div>
    );
};
