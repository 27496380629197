import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { Typography, Grid, IconButton, Stack, Tooltip, Hidden } from '@mui/material';
import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { RtmEventDisplay, RtmDescription, RtmCode } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { RTMMonthCycle, RTMPatientRecord } from '@/common/types';
import { isBillable, preventParentLinkClick } from '@/common/utils';
import { CopyIcon } from '@/components/common/Icons/CopyIcon';
import { DownloadIcon } from '@/components/common/Icons/DownloadIcon';
import { resultIsError } from '@/services/HttpService';
import { Accordion, AccordionSummary } from './Accordion';
import { MarkAsBilledAction } from './MarkAsBilledAction';
import { StatusChip } from './StatusChip';
import {
    RTMCodeTitle,
    AccordionDetails,
    StatusGridWrapper,
    ActionGridWrapper,
    CycleDetailText,
    NotesGridWrapper,
} from './styles';
import { summarize98977Events } from './utils';

export const RTMMonthlySummaryRow = ({
    cycle,
    patientRecord,
}: {
    cycle: RTMMonthCycle;
    patientRecord: RTMPatientRecord;
}) => {
    const { id, code, events, status, startDate, endDate, pdfKey } = cycle;
    const pdfAnchorRef = useRef<HTMLAnchorElement>(null);
    const { firstName, lastName } = patientRecord;
    const { errorToast, successToast } = useToast();
    const [detailsText, setDetailsText] = useState<string[]>([]);
    const [pdfDownloadURL, setPDFDownloadURL] = useState<string>();
    const { httpService } = useHttpContext();

    const { totalDays, proCnt, sessionCnt } = summarize98977Events(events);

    const handleCopyClick = (e) => {
        preventParentLinkClick(e);
    };

    const getSetPdfUrl = async () => {
        if (pdfKey) {
            const res = await httpService.getPatientActivityPdf(id);
            if (resultIsError(res)) {
                Sentry.captureException('Failed to get pdf url on rtm monthly summary with pdf key');
                return;
            }
            setPDFDownloadURL(res.pdfUrl);
        }
    };

    const handleDownloadMouseOver = async () => {
        await getSetPdfUrl();
    };

    const handleDownloadClick = async (e: MouseEvent<HTMLAnchorElement>) => {
        if (pdfKey) {
            e.stopPropagation();
            if (!pdfDownloadURL) {
                await getSetPdfUrl();
            }
            pdfAnchorRef.current?.click();
        } else {
            e.preventDefault();
        }
    };

    const generateText = (): string[] => {
        const text = [`${firstName} ${lastName}\n${code}\t\t${RtmDescription[code]}`];

        if ([RtmCode.PATIENT_INTERACTION, RtmCode.ADDITIONAL_PATIENT_INTERACTION].includes(code)) {
            events.map((e) => {
                const date = DateTime.fromJSDate(new Date(e.dateTime)).toFormat('LL/dd/yyyy');
                text.push(
                    `${date} - ${e.patientInteractionType ? 'Interactive Communication' : RtmEventDisplay[e.type]}${
                        ` (${e.minutes}` + ' minutes)'
                    }`,
                );
            });

            return text;
        }

        const displayStartDate = DateTime.fromJSDate(new Date(startDate)).toFormat('LL/dd/yyyy');
        const displayEndDate = DateTime.fromJSDate(new Date(endDate)).toFormat('LL/dd/yyyy');

        text.push(`RTM Cycle:\t${displayStartDate}\t-\t${displayEndDate}`);

        if (code === RtmCode.DEVICE_AND_DATA_TRANSMISSION) {
            text.push(`Total Patient Activity: ${totalDays} days`);
            sessionCnt && text.push(`Exer Health Sessions:\ ${sessionCnt} days`);
            proCnt > 0 && text.push(`PRO Responses: ${proCnt} days`);
            return text;
        }

        if (code === RtmCode.PATIENT_SETUP) {
            const displayEventDate = DateTime.fromJSDate(new Date(events[0].dateTime)).toFormat('LL/dd/yyyy');
            text.push(`${displayEventDate} - Exer Initial Onboarding and Education complete.`);
        }

        return text;
    };

    useEffect(() => {
        setDetailsText(generateText());
    }, [events]);

    const copiedSuccessfully = (result: boolean) => {
        if (result) {
            successToast(`Successfully copied ${code} for ${firstName} ${lastName}`);
        } else {
            errorToast('Something went wrong copying the data.');
        }
    };

    detailsText.slice(2);

    return (
        <Accordion>
            <AccordionSummary aria-controls={`${id}-${code}`} id={`${id}-${code}`}>
                <Grid wrap="nowrap" container style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid zeroMinWidth md={7} lg={4} item>
                        <RTMCodeTitle noWrap>
                            {code} - {RtmDescription[code]}
                        </RTMCodeTitle>
                    </Grid>
                    <Hidden only={['xs', 'sm', 'md']}>
                        <NotesGridWrapper zeroMinWidth sm={3} item>
                            <Typography noWrap>
                                {code === RtmCode.DEVICE_AND_DATA_TRANSMISSION
                                    ? `${totalDays}/30 days - Ends ${DateTime.fromISO(endDate).toFormat('MM/dd/yyyy')}`
                                    : ''}
                            </Typography>
                        </NotesGridWrapper>
                    </Hidden>
                    <StatusGridWrapper xs={2} item>
                        <StatusChip status={status} />
                    </StatusGridWrapper>
                    <ActionGridWrapper xs={3} item>
                        {pdfKey ? (
                            <Tooltip title="Download PDF">
                                <a
                                    ref={pdfAnchorRef}
                                    href={pdfDownloadURL || undefined}
                                    onMouseOver={handleDownloadMouseOver}
                                    onClick={handleDownloadClick}
                                    rel="noreferrer"
                                >
                                    <IconButton style={{ color: '#737580', fontSize: 20 }}>
                                        <DownloadIcon />
                                    </IconButton>
                                </a>
                            </Tooltip>
                        ) : null}
                        {isBillable(status) ? <MarkAsBilledAction cycle={cycle} patientRecord={patientRecord} /> : null}
                        {isBillable(status) ? (
                            <CopyToClipboard
                                text={detailsText.join('\n')}
                                onCopy={(_, result) => copiedSuccessfully(result)}
                            >
                                <IconButton onClick={handleCopyClick} style={{ color: '#737580', fontSize: 20 }}>
                                    <Tooltip title={`Copy ${code} data`}>
                                        <div
                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        >
                                            <CopyIcon />
                                        </div>
                                    </Tooltip>
                                </IconButton>
                            </CopyToClipboard>
                        ) : null}
                    </ActionGridWrapper>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={2}>
                    {detailsText.slice(1).map((str) => (
                        <CycleDetailText key={str}>{str}</CycleDetailText>
                    ))}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};
