import React, { useEffect, useRef, useState } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { SessionReplayCanvasWrapper } from '@/components/common/Pose/styles';
import { drawExerLandmarks, getAngle } from '@/components/common/Pose/utils';
import { Result, resultIsError } from '@/services/HttpService';
import { GaitPoseLog } from './types';

interface GaitSessionReplayProps {
    sessionUUID: string;
}

export const GaitSessionReplay = (props: GaitSessionReplayProps) => {
    const { sessionUUID } = props;
    const { httpService } = useHttpContext();
    const outputCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const [canvasCtx, setCanvasCtx] = useState<CanvasRenderingContext2D | null>();
    const [poseLog, setPoseLog] = useState<GaitPoseLog>();
    const [canvasDimensions, setCanvasDimensions] = useState<{ width: number; height: number }>();
    const intervalId = useRef<NodeJS.Timer>();
    const i = useRef<number>(0);

    const [chartInstance, setChartInstance] = useState<ChartJS | null>(null);
    const ChartContainer = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        (async () => {
            const res = await httpService.adminGetGaitSessionPoseLogSignedUrl(sessionUUID);
            if (resultIsError(res)) {
                alert(res.message);
                return;
            }
            const poseRes: Result<GaitPoseLog> = await httpService.getExternalUrl(res);
            if (resultIsError(poseRes)) {
                alert(poseRes.message);
                return;
            }
            if (poseRes?.[0]) {
                setCanvasDimensions({
                    width: Math.floor(poseRes?.[0].image_shape[1] / 2),
                    height: Math.floor(poseRes?.[0].image_shape[0] / 2),
                });
            }
            setPoseLog(poseRes as GaitPoseLog);
        })();
    }, []);

    useEffect(() => {
        const iid = setInterval(() => {
            if (canvasCtx && poseLog?.[i.current] && outputCanvasRef.current) {
                canvasCtx.save();
                canvasCtx.clearRect(0, 0, outputCanvasRef.current.width, outputCanvasRef.current.height);
                const avgConfidence =
                    poseLog[i.current].confidences.reduce((i, j) => {
                        return i + j;
                    }) / poseLog[i.current].confidences.length;
                if (avgConfidence > 0.1) {
                    drawExerLandmarks(poseLog[i.current].keypoints, canvasCtx, 0.5, '#2dc1bb');
                    const a = getAngle(
                        poseLog[i.current].keypoints[6],
                        poseLog[i.current].keypoints[2],
                        poseLog[i.current].keypoints[1],
                        true,
                    );
                    chartInstance?.data.datasets[0].data.push(a);
                    chartInstance?.data.labels?.push('');
                    chartInstance?.update();
                }
            }
            i.current++;
        }, 33);
        intervalId.current = iid;
        () => {
            clearInterval(intervalId.current);
            i.current = 0;
        };
    }, [poseLog]);

    useEffect(() => {
        if (!canvasCtx && outputCanvasRef.current) {
            setCanvasCtx(outputCanvasRef.current.getContext('2d'));
        }
    }, [canvasCtx, outputCanvasRef]);

    useEffect(() => {
        ChartJS.register(...registerables);

        const options = {
            responsive: true,
            redraw: true,
            scales: {
                y: {
                    beginAtZero: true,
                    min: 0,
                    max: 60,
                    ticks: {
                        stepSize: 10,
                    },
                },
            },
            plugins: {
                legend: {
                    display: true,
                },
                title: {
                    display: false,
                    text: '',
                },
            },
        };

        if (!ChartContainer?.current) return;
        const ctx = ChartContainer.current.getContext('2d');
        if (ctx) {
            const newChartInstance = new ChartJS(ctx, {
                type: 'line',
                data: {
                    datasets: [
                        {
                            data: [],
                            tension: 0.1,
                            label: 'Hip Flexion',
                        },
                    ],
                    labels: [],
                },
                options: options,
            });
            setChartInstance(newChartInstance);
        }
    }, []);

    return (
        <>
            <SessionReplayCanvasWrapper width={'100%'} height={'100%'}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <canvas
                        ref={outputCanvasRef}
                        width={canvasDimensions?.width || 540}
                        height={canvasDimensions?.height || 960}
                    />
                </div>
                {/* <canvas ref={ChartContainer} id="angle-chart" /> */}
            </SessionReplayCanvasWrapper>
        </>
    );
};
