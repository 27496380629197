import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { GaitPatientPath, gaitPatientPaths } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { mixpanel } from '@/common/mixpanel';
import { sortByGaitSessionDate } from '@/common/utils';
import { FocusedModal } from '@/components/Health/HealthPatient/FocusedModal';
import { usePatientContext } from '@/components/Patients/Patient/context/hooks/PatientContext';
import { ChartSkeletonLoader } from '@/components/common/SkeletonLoaders/ChartSkeletonLoader';
import { DateRangeState } from '@/recoil/atoms/dateRangeAtom';
import { GaitSessionsState } from '@/recoil/atoms/gaitSessionsAtom';
import { GaitSessionsSelector } from '@/recoil/selectors/gaitSessionsSelector';
import { resultIsError } from '@/services/HttpService';
import { PatientTabs } from './Tabs/PatientTabs';

interface Props {
    patientRecordId: number;
}

export const GaitPatientSessionsWrapper = (props: Props) => {
    const { patientRecordId } = props;
    const [dateRange] = useRecoilState(DateRangeState);
    const { patientRecord } = usePatientContext();
    const [gaitSortedSessions, setSortedGaitSessions] = useRecoilState(GaitSessionsState);
    const { gaitSessionsFilteredByDateRange, gaitChartsData, showCharts } = useRecoilValue(GaitSessionsSelector);
    const { httpService } = useHttpContext();
    const [loading, setLoading] = useState(false);
    const [currentChart, setCurrentChart] = useState('');
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { patientSection } = useParams<{ patientSection: string }>();
    const { errorToast } = useToast();

    const getInitTab = () => {
        switch (true) {
            case !patientSection:
                navigate(`${pathname}/${GaitPatientPath.activity}`, { replace: true });
                return 0;
            case patientSection === GaitPatientPath.activity:
                return 0;
            case patientSection === GaitPatientPath.progress:
                return 1;
            default:
                return 0;
        }
    };
    const [currentTab, setCurrentTab] = useState(getInitTab());

    const getSessions = async () => {
        setLoading(true);
        const res = await httpService.getPatientGaitSessions(patientRecordId.toString());
        setLoading(false);
        if (resultIsError(res)) {
            errorToast('Could not get gait sessions.');
            return;
        }
        const sortedSessions = sortByGaitSessionDate(res);
        setSortedGaitSessions(sortedSessions);
    };

    useEffect(() => {
        (async () => {
            await getSessions();
        })();
    }, []);

    useEffect(() => {
        if (gaitChartsData && Object.keys(gaitChartsData).length) {
            setCurrentChart(gaitChartsData[Object.keys(gaitChartsData)[0]].id);
        }
    }, [gaitChartsData]);

    const handleRefreshClick = async () => {
        await getSessions();
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
        navigate(`${pathname}/../${gaitPatientPaths[newValue]}`, { replace: true });
    };
    const handleChartSelection = ({ optionName, optionId }: { optionName: string; optionId: string }) => {
        setCurrentChart(optionId);
    };

    const handleProgressChartTabClick = () => {
        mixpanel.track('Viewed Gait Charts');
    };

    return (
        <>
            {loading ? (
                <ChartSkeletonLoader />
            ) : (
                <>
                    <PatientTabs
                        currentTab={currentTab}
                        handleTabChange={handleTabChange}
                        aria-label="Gait patient tabs"
                        handleProgressChartTabClick={handleProgressChartTabClick}
                        handleRefreshClick={handleRefreshClick}
                        isInModal={false}
                        dateRange={dateRange}
                        gaitSortedSessions={gaitSortedSessions}
                        gaitSessionsFilteredByDateRange={gaitSessionsFilteredByDateRange}
                        showCharts={showCharts}
                        handleChartSelection={handleChartSelection}
                        currentChart={currentChart}
                    />
                    <FocusedModal title={`${patientRecord?.patient?.firstName} ${patientRecord?.patient?.lastName}`}>
                        <PatientTabs
                            currentTab={currentTab}
                            handleTabChange={handleTabChange}
                            aria-label="Gait patient tabs"
                            handleProgressChartTabClick={handleProgressChartTabClick}
                            handleRefreshClick={handleRefreshClick}
                            isInModal={true}
                            dateRange={dateRange}
                            gaitSortedSessions={gaitSortedSessions}
                            gaitSessionsFilteredByDateRange={gaitSessionsFilteredByDateRange}
                            showCharts={showCharts}
                            handleChartSelection={handleChartSelection}
                            currentChart={currentChart}
                        />
                    </FocusedModal>
                </>
            )}
        </>
    );
};
