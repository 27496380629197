import { createTheme, ThemeOptions } from '@mui/material/styles';

export interface Colors {
    readonly success: string;
    readonly warning: string;
    readonly error: string;
    readonly white: string;
}

export const colors: Colors = {
    success: '#b19afe',
    warning: '#c79216',
    error: '#ed473a',
    white: '#fff',
};

export const baseThemeOptions: ThemeOptions = {
    typography: {
        htmlFontSize: 16,
        fontSize: 16,
        body1: {
            fontSize: '1rem',
            lineHeight: 1.3,
        },
        body2: {
            fontSize: '1.125rem',
            lineHeight: 1.42,
        },
        h1: {
            fontSize: '3.5rem',
            lineHeight: 1.25,
            marginTop: 0,
        },
        h2: {
            fontSize: '2.5rem',
            lineHeight: 1.4,
        },
        h3: {
            fontSize: '2rem',
            lineHeight: 1.375,
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: 500,
            lineHeight: 1.33,
        },
        h5: {
            fontSize: '1.25rem',
            lineHeight: 1.4,
        },
        caption: {
            fontSize: '.75rem',
            lineHeight: 1.333,
            fontWeight: '500',
            textTransform: 'uppercase',
        },
        subtitle1: {
            fontSize: '1rem',
            lineHeight: 1.5,
            fontWeight: '400',
        },
        subtitle2: {
            fontSize: '.85rem',
            lineHeight: 1.5,
            fontWeight: '400',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 1800,
            chromebook: 1330,
        },
    },
    palette: {
        neutral: {
            main: '#f9fafc',
        },
        background: {
            default: '#f3f4f7',
        },
        primary: {
            50: '#f9f7ff',
            100: '#efebff',
            200: '#e6deff',
            300: '#d8cdfe',
            400: '#b19afe',
            500: '#6a41f1',
            600: '#4c21d9',
            700: '#3712b3',
            800: '#280c80',
            900: '#110534',
        },
        info: {
            100: '#F4F9FE',
            200: '#DBEEFF',
            300: '#C2E0FC',
            400: '#98CBFA',
            500: '#47A6FF',
            600: '#1E8BF1',
            700: '#0464BD',
            800: '#003566',
            900: '#00182E',
        },
        warning: {
            100: '#fff3d9',
            200: '#ffe3ae',
            300: '#fed88e',
            400: '#ffbf47',
            500: '#fab531',
            600: '#efa922',
            700: '#e09911',
            800: '#c78403',
            900: '#664201',
        },
        success: {
            100: '#E6FAF8',
            200: '#CEF5F2',
            300: '#9CE9E4',
            400: '#2CD5CB',
            500: '#2DC1B8',
            600: '#01ABA1',
            700: '#009990',
            800: '#01746D',
            900: '#012D2B',
        },
        error: {
            100: '#ffeeef',
            200: '#fedfdf',
            300: '#fed0ce',
            400: '#fdb0ae',
            500: '#fc615d',
            600: '#f2524e',
            700: '#d94846',
            800: '#c74241',
            900: '#993431',
        },
        grey: {
            50: '#f7f8fa',
            100: '#f3f4f7',
            200: '#e6e8f0',
            300: '#dadce5',
            400: '#c2c4cc',
            500: '#93959e',
            600: '#737580',
            700: '#55575e',
            800: '#292b32',
            900: '#0a0a0d',
        },
        technoBlue: {
            main: '#00ffff',
            light: '#00ffff',
            dark: '#00fffff',
            contrastText: '#363636',
        },
    },
};

const baseExerTheme = createTheme(baseThemeOptions);

export const exerThemeOptions: ThemeOptions = {
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '&::selection': {
                    backgroundColor: baseExerTheme.palette.primary[400],
                    color: baseExerTheme.palette.common.white,
                },
            },
        },
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'caption' },
                    style: {
                        color: baseExerTheme.palette.grey[600],
                    },
                },
                {
                    props: { variant: 'supheader' },
                    style: {
                        color: baseExerTheme.palette.primary[600],
                        fontWeight: baseExerTheme.typography.fontWeightMedium,
                        fontSize: baseExerTheme.typography.caption.fontSize,
                    },
                },
            ],
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    textTransform: 'initial',
                    '&.Mui-error': {
                        textTransform: 'none',
                    },
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    fontSize: baseExerTheme.typography.body1.fontSize,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    fontFamily: `'DM Sans', sans-serif`,
                    fontWeight: '500',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: `'DM Sans', sans-serif`,
                    textTransform: 'capitalize',
                    backgroundColor: baseExerTheme.palette.primary[500],
                    color: colors.white,
                    padding: `${baseExerTheme.spacing(1.25)} ${baseExerTheme.spacing(4)}`,
                    boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                    border: '1px solid transparent',
                    '&:hover': {
                        background: baseExerTheme.palette.primary[400],
                        boxShadow: '0 0 0',
                    },
                    '&:active': {
                        background: baseExerTheme.palette.primary[600],
                    },
                    '&:focus': {
                        boxShadow: `0 0 0 4px rgba(177, 154, 254, .5)`,
                    },
                    '&:disabled': {
                        background: baseExerTheme.palette.grey[200],
                        color: baseExerTheme.palette.grey[400],
                        cursor: 'not-allowed',
                    },
                    '& .MuiSvgIcon-root': {
                        fontSize: 'inherit',
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'secondary' },
                    style: {
                        backgroundColor: baseExerTheme.palette.primary[100],
                        color: baseExerTheme.palette.primary[600],
                        '&:hover': {
                            color: baseExerTheme.palette.primary[400],
                            backgroundColor: baseExerTheme.palette.primary[50],
                            boxShadow: `0 0 0 2px ${baseExerTheme.palette.primary[300]}`,
                        },
                        '&:active': {
                            color: baseExerTheme.palette.primary[500],
                            backgroundColor: baseExerTheme.palette.primary[100],
                            boxShadow: `0 0 0 2px ${baseExerTheme.palette.primary[400]}`,
                        },
                        '&:focus': {
                            color: baseExerTheme.palette.primary[600],
                            backgroundColor: baseExerTheme.palette.primary[50],
                            boxShadow: `0 0 0 4px ${baseExerTheme.palette.primary[100]}`,
                        },
                    },
                },
                {
                    props: { variant: 'tertiary' },
                    style: {
                        fontSize: baseExerTheme.typography.body2.fontSize,
                        backgroundColor: 'transparent',
                        border: `1px solid ${baseExerTheme.palette.grey[600]}`,
                        color: baseExerTheme.palette.grey[600],
                        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                        '&:hover': {
                            color: baseExerTheme.palette.grey[400],
                            border: `1px solid ${baseExerTheme.palette.grey[400]}`,
                            boxShadow: '0 0 0!important',
                            background: colors.white,
                        },
                        '&:active': {
                            backgroundColor: 'transparent',
                            border: `1px solid ${baseExerTheme.palette.grey[700]}`,
                            color: baseExerTheme.palette.grey[700],
                        },
                        '&:focus': {
                            border: `1px solid ${baseExerTheme.palette.grey[600]}`,
                            color: baseExerTheme.palette.grey[600],
                            boxShadow: `0 0 0 4px ${baseExerTheme.palette.grey[200]}`,
                        },
                        '&:disabled': {
                            backgroundColor: 'transparent',
                            border: `1px solid ${baseExerTheme.palette.grey[400]}`,
                            color: baseExerTheme.palette.grey[400],
                        },
                    },
                },
                {
                    props: { size: 'small' },
                    style: {
                        whiteSpace: 'nowrap',
                        fontSize: baseExerTheme.typography.body1.fontSize,
                        padding: '6px 20px',
                    },
                },
                {
                    props: { size: 'medium' },
                    style: {
                        whiteSpace: 'nowrap',
                        fontSize: baseExerTheme.typography.body1.fontSize,
                        padding: '13px 45px',
                    },
                },
                {
                    props: { size: 'large' },
                    style: {
                        whiteSpace: 'nowrap',
                        fontSize: baseExerTheme.typography.body1.fontSize,
                        padding: '21px 46px',
                    },
                },
                {
                    props: { variant: 'filter' },
                    style: {
                        fontSize: baseExerTheme.typography.pxToRem(11),
                        backgroundColor: baseExerTheme.palette.primary[200],
                        border: 'transparent',
                        borderRadius: '20px',
                        color: baseExerTheme.palette.primary[600],
                        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                        padding: '3px 12px',
                        minWidth: 'unset',
                        margin: `6px 4px`,
                        textAlign: 'center',
                        '&:hover': {
                            color: baseExerTheme.palette.primary[400],
                            backgroundColor: baseExerTheme.palette.primary[200],
                            boxShadow: '0 0 0!important',
                        },
                        '&:active': {
                            backgroundColor: baseExerTheme.palette.primary[200],
                            color: baseExerTheme.palette.primary[700],
                        },
                        '&:focus': {
                            color: baseExerTheme.palette.primary[600],
                            backgroundColor: baseExerTheme.palette.primary[200],
                            boxShadow: `0 0 0 4px ${baseExerTheme.palette.grey[200]}`,
                        },
                        '& .MuiButton-endIcon': {
                            marginLeft: '0',
                            '& .MuiSvgIcon-root': {
                                fontSize: baseExerTheme.typography.pxToRem(14),
                            },
                        },
                    },
                },
                {
                    props: { variant: 'text' },
                    style: {
                        fontSize: baseExerTheme.typography.body1.fontSize,
                        backgroundColor: 'transparent',
                        color: baseExerTheme.palette.grey[600],
                        padding: '4px 8px',
                        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                        '&:hover': {
                            boxShadow: '0 0 0!important',
                            background: colors.white,
                        },
                        '&:active': {
                            backgroundColor: 'transparent',
                            color: baseExerTheme.palette.grey[700],
                        },
                        '&:focus': {
                            boxShadow: `0 0 0 4px ${baseExerTheme.palette.grey[200]}`,
                        },
                        '&:disabled': {
                            backgroundColor: 'transparent',
                            color: baseExerTheme.palette.grey[400],
                        },
                    },
                },
                {
                    props: { variant: 'textPrimary' },
                    style: {
                        fontSize: baseExerTheme.typography.body1.fontSize,
                        backgroundColor: 'transparent',
                        color: baseExerTheme.palette.primary[500],
                        padding: '4px 8px',
                        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                        '&:hover': {
                            boxShadow: '0 0 0!important',
                            background: colors.white,
                        },
                        '&:active': {
                            backgroundColor: 'transparent',
                            color: baseExerTheme.palette.primary[700],
                        },
                        '&:focus': {
                            boxShadow: `0 0 0 4px ${baseExerTheme.palette.primary[200]}`,
                        },
                        '&:disabled': {
                            backgroundColor: 'transparent',
                            color: baseExerTheme.palette.primary[400],
                        },
                    },
                },
                {
                    props: { variant: 'card' },
                    style: {
                        fontSize: baseExerTheme.typography.body1.fontSize,
                        backgroundColor: 'transparent',
                        color: baseExerTheme.palette.primary[500],
                        padding: baseExerTheme.spacing(2),
                        border: `1px solid ${baseExerTheme.palette.grey[300]}`,
                        boxShadow: '0px 2px 5px rgba(16, 5, 51, 0.04)!important',
                        width: '100%',
                        '&:hover': {
                            // boxShadow: '0 0 0!important',
                            background: baseExerTheme.palette.common.white,
                        },
                        '&:active': {
                            backgroundColor: 'transparent',
                            color: baseExerTheme.palette.primary[700],
                        },
                        '&:focus': {
                            boxShadow: `0 0 0 4px ${baseExerTheme.palette.primary[200]}`,
                        },
                        '&:disabled': {
                            backgroundColor: 'transparent',
                            color: baseExerTheme.palette.primary[400],
                        },
                    },
                },
                {
                    props: { size: 'xs' },
                    style: {
                        padding: '4px 8px',
                        fontSize: baseExerTheme.typography.body1.fontSize,
                        '.MuiButton-startIcon': {
                            paddingLeft: baseExerTheme.spacing(1),
                        },
                    },
                },
                {
                    props: { size: 'xs', variant: 'text' },
                    style: {
                        fontSize: baseExerTheme.typography.caption.fontSize,
                    },
                },
            ],
        },
        MuiPagination: {
            styleOverrides: {
                root: {},
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: baseExerTheme.palette.grey[400],
                    '& .MuiSvgIcon-root': {
                        fontSize: baseExerTheme.typography.h5.fontSize,
                        borderRadius: '3px',
                    },
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    width: '100%',
                    borderSpacing: 0,
                    borderCollapse: 'collapse',
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    fontSize: baseExerTheme.typography.pxToRem(14),
                    padding: '2px 16px',
                    color: baseExerTheme.palette.grey[500],
                    borderColor: baseExerTheme.palette.grey[200],
                    fontWeight: baseExerTheme.typography.fontWeightBold,
                    '&.Mui-selected': {
                        backgroundColor: baseExerTheme.palette.grey[200],
                        color: baseExerTheme.palette.primary[700],
                    },
                },
            },
            variants: [
                {
                    props: { size: 'large' },
                    style: {
                        width: baseExerTheme.spacing(5),
                        height: baseExerTheme.spacing(5),
                    },
                },
            ],
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    height: '7px',
                    paddingRight: '8px',
                    paddingLeft: '8px',
                },
                rail: {
                    backgroundColor: baseExerTheme.palette.grey[200],
                },
                track: {
                    backgroundColor: baseExerTheme.palette.grey[700],
                },
                thumb: {
                    backgroundColor: baseExerTheme.palette.grey[700],
                },
                mark: {
                    width: '4px',
                    height: '4px',
                    borderRadius: '50%',
                    '&.MuiSlider-mark': {
                        display: 'none',
                    },
                    '&.MuiSlider-mark ~ .MuiSlider-mark': {
                        display: 'block',
                    },
                    '&.MuiSlider-markActive': {
                        backgroundColor: baseExerTheme.palette.grey[700],
                    },
                },
                valueLabel: {
                    backgroundColor: baseExerTheme.palette.primary[600],
                    fontSize: baseExerTheme.typography.pxToRem(12),
                },
                markLabel: {
                    fontSize: baseExerTheme.typography.body1.fontSize,
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    borderBottom: `1px solid ${baseExerTheme.palette.grey[500]}`,
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: baseExerTheme.typography.pxToRem(14),
                    fontWeight: '500',
                    padding: `10px 16px`,
                },
                sizeSmall: {
                    fontSize: baseExerTheme.typography.pxToRem(14),
                    fontWeight: '500',
                },
                sizeMedium: {
                    fontSize: baseExerTheme.typography.pxToRem(14),
                    fontWeight: '500',
                    padding: `16px 16px`,
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontSize: baseExerTheme.typography.pxToRem(12),
                    fontWeight: '500',
                    borderRadius: 20,
                    padding: '0 8px',
                    cursor: 'inherit',
                },
            },
            variants: [
                {
                    props: { color: 'info' },
                    style: {
                        backgroundColor: baseExerTheme.palette.info[200],
                        color: baseExerTheme.palette.info[600],
                    },
                },
                {
                    props: { color: 'success' },
                    style: {
                        backgroundColor: baseExerTheme.palette.success[200],
                        color: baseExerTheme.palette.success[600],
                    },
                },
                {
                    props: { color: 'warning' },
                    style: {
                        backgroundColor: baseExerTheme.palette.warning[200],
                        color: baseExerTheme.palette.warning[700],
                    },
                },
                {
                    props: { color: 'error' },
                    style: {
                        backgroundColor: baseExerTheme.palette.error[200],
                        color: baseExerTheme.palette.error[600],
                    },
                },
            ],
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: 'inherit',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    '.MuiMenu-list': {
                        minWidth: baseExerTheme.spacing(25),
                    },
                    '& > .MuiPaper-root': {
                        boxShadow: '0px 17px 20px rgba(16, 5, 51, 0.12)',
                        border: `1px solid ${baseExerTheme.palette.grey[200]}`,
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    paddingTop: baseExerTheme.spacing(1),
                    paddingBottom: baseExerTheme.spacing(1),
                    '.MuiMenu-list': {
                        minWidth: baseExerTheme.spacing(25),
                    },
                    '& dt': {
                        display: 'block',
                        fontSize: 14,
                    },
                    '& dd': {
                        display: 'block',
                        fontSize: 10,
                        marginLeft: 0,
                        marginTop: 8,
                        marginBottom: 8,
                        whiteSpace: 'break-spaces',
                    },
                    '& .MuiListItemText-primary': {
                        fontSize: baseExerTheme.typography.body1.fontSize,
                        color: baseExerTheme.palette.grey[700],
                        fontWeight: baseExerTheme.typography.fontWeightLight,
                    },
                    '& .MuiListItemIcon-root': {
                        minWidth: '26px',
                        color: baseExerTheme.palette.grey[500],
                        fontSize: baseExerTheme.typography.fontSize,
                        '.MuiSvgIcon-root': {
                            marginRight: baseExerTheme.spacing(1.5),
                        },
                    },
                },
            },
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    borderBottom: `1px solid ${baseExerTheme.palette.grey[300]}`,
                    margin: `0 -${baseExerTheme.spacing(4)} ${baseExerTheme.spacing(3.125)}`,
                    padding: `${baseExerTheme.spacing(1)} ${baseExerTheme.spacing(4)} ${baseExerTheme.spacing(2.25)}}`,
                    '& a': {
                        fontSize: baseExerTheme.typography.fontSize,
                        fontWeight: baseExerTheme.typography.fontWeightMedium,
                        textDecoration: 'none',
                        color: baseExerTheme.palette.grey[500],
                        '&:hover': {
                            color: baseExerTheme.palette.primary[500],
                        },
                        '&:focus': {
                            color: baseExerTheme.palette.primary[500],
                        },
                    },
                    '& span': {
                        fontSize: baseExerTheme.typography.fontSize,
                        fontWeight: baseExerTheme.typography.fontWeightMedium,
                        textDecoration: 'none',
                        color: baseExerTheme.palette.grey[900],
                    },
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: baseExerTheme.palette.grey[500],
                    borderColor: baseExerTheme.palette.grey[300],
                    fontSize: baseExerTheme.typography.pxToRem(12),
                    '&.Mui-selected': {
                        border: '0',
                    },
                    '&.MuiPaginationItem-previousNext': {
                        color: baseExerTheme.palette.primary[500],
                    },
                    '&.MuiPaginationItem-ellipsis': {
                        border: `1px solid ${baseExerTheme.palette.grey[300]}`,
                        borderRadius: '4px',
                        lineHeight: '1.82',
                        height: '32px',
                    },
                    '&:disabled': {
                        border: '0',
                        opacity: '1',
                        color: baseExerTheme.palette.grey[400],
                        backgroundColor: baseExerTheme.palette.grey[100],
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    boxShadow: `inset 0px -2px 0px 0px ${baseExerTheme.palette.grey[50]}`,
                    marginBottom: baseExerTheme.spacing(1),
                },
                indicator: {
                    backgroundColor: baseExerTheme.palette.primary[600],
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontWeight: '700',
                    color: baseExerTheme.palette.grey[600],
                    textTransform: 'capitalize',
                    padding: `12px 0`,
                    margin: `0 ${baseExerTheme.spacing(1)}`,
                    '&.Mui-selected': {
                        color: baseExerTheme.palette.primary[600],
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: baseExerTheme.palette.grey[600],
                    '&.Mui-checked': {
                        color: baseExerTheme.palette.primary[700],
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    color: baseExerTheme.palette.grey[900],
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: baseExerTheme.palette.common.white,
                    '& fieldset > legend': {
                        visibility: 'visible',
                        color: 'transparent',
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        transition: '.25s ease-in-out',
                        borderColor: baseExerTheme.palette.grey[300],
                    },
                    '&&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: baseExerTheme.palette.primary[500],
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                container: {},
                root: {
                    color: 'inherit',
                    '& .ieNaoV': {
                        color: 'inherit',
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: baseExerTheme.spacing(2),
                    boxShadow: '0 0 0',
                },
            },
            variants: [
                {
                    props: { elevation: 4 },
                    style: {
                        boxShadow: '0px 2px 5px rgba(16, 5, 51, 0.04)',
                    },
                },
            ],
        },
        MuiPaper: {
            variants: [
                {
                    props: { variant: 'radio' },
                    style: {
                        border: `1px solid ${baseExerTheme.palette.grey[300]}`,
                        '.MuiFormControlLabel-root': {
                            display: 'flex',
                            alignItems: 'flex-start',
                            margin: 0,
                        },
                        '.MuiFormControlLabel-label': {
                            display: 'flex',
                            width: '100%',
                        },
                        '.MuiRadio-root': {
                            display: 'flex',
                            margin: `2px ${baseExerTheme.spacing(1)} 0 2px`,
                        },
                    },
                },
                {
                    props: { variant: 'table' },
                    style: {
                        border: `1px solid ${baseExerTheme.palette.grey[300]}`,
                        display: 'flex',
                        flexDirection: 'column',

                        '.MuiCardHeader-title': {
                            fontSize: baseExerTheme.typography.h3.fontSize,
                            fontWeight: baseExerTheme.typography.fontWeightMedium,
                            color: baseExerTheme.palette.primary[500],
                        },
                        '.MuiCardContent-root': {
                            flex: '1',
                        },
                        '.MuiCardHeader-subheader': {
                            fontSize: baseExerTheme.typography.body1.fontSize,
                            fontWeight: baseExerTheme.typography.fontWeightRegular,
                            color: baseExerTheme.palette.grey[900],
                        },
                        '.MuiCardActions-root': {
                            minHeight: baseExerTheme.spacing(7.25),
                        },
                    },
                },
            ],
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    background: baseExerTheme.palette.primary.main,
                },
                tooltipArrow: {
                    background: baseExerTheme.palette.primary.main,
                },
            },
        },
        MuiPopper: {
            defaultProps: {
                style: {
                    zIndex: '9999',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    alignItems: 'center',
                    boxShadow: '0px 2px 5px rgba(16, 5, 51, 0.04)',
                },
            },
            variants: [
                {
                    props: { color: 'success' },
                    style: {
                        color: baseExerTheme.palette.primary[700],
                        backgroundColor: baseExerTheme.palette.primary[50],
                        border: `1px solid ${baseExerTheme.palette.primary[300]}`,
                        padding: `${baseExerTheme.spacing(1.5)} ${baseExerTheme.spacing(2)}`,
                    },
                },
            ],
        },
    },
};

export const exerTheme = createTheme(baseExerTheme, exerThemeOptions);
