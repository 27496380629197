import React, { useEffect, useRef, useState } from 'react';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { SessionReplayCanvasWrapper } from '@/components/common/Pose/styles';
import { drawExerLandmarks } from '@/components/common/Pose/utils';
import { Result, resultIsError } from '@/services/HttpService';
import { ScanPoseLog } from './types';

interface ScanSessionReplayProps {
    sessionUUID: string;
}

export const ScanSessionReplay = (props: ScanSessionReplayProps) => {
    const { sessionUUID } = props;
    const { httpService } = useHttpContext();
    const outputCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const [canvasCtx, setCanvasCtx] = useState<CanvasRenderingContext2D | null>();
    const [poseLog, setPoseLog] = useState<ScanPoseLog>();
    const [canvasDimensions, setCanvasDimensions] = useState<{ width: number; height: number }>();
    const intervalId = useRef<NodeJS.Timer>();
    const i = useRef<number>(0);

    useEffect(() => {
        (async () => {
            const res = await httpService.getScanSessionPoseLogSignedUrl(sessionUUID);
            console.log(res);
            if (resultIsError(res)) {
                alert(res.message);
                return;
            }
            const poseRes: Result<ScanPoseLog> = await httpService.getExternalUrl(res);
            if (resultIsError(poseRes)) {
                console.log('Could not get log.');
                return;
            }
            if (poseRes?.image_shape[0] && poseRes?.image_shape[1]) {
                setCanvasDimensions({
                    width: Math.floor(poseRes?.image_shape[1] / 2),
                    height: Math.floor(poseRes?.image_shape[0] / 2),
                });
            }
            setPoseLog(poseRes as ScanPoseLog);
        })();
    }, []);

    useEffect(() => {
        const iid = setInterval(() => {
            if (canvasCtx && poseLog?.poses[i.current] && outputCanvasRef.current) {
                canvasCtx.save();
                canvasCtx.clearRect(0, 0, outputCanvasRef.current.width, outputCanvasRef.current.height);

                poseLog.image_shape[0];
                drawExerLandmarks(poseLog.poses[i.current], canvasCtx, 0.5, '#2dc1bb');
            }
            i.current++;
        }, 33);
        intervalId.current = iid;
        () => {
            clearInterval(intervalId.current);
            i.current = 0;
        };
    }, [poseLog]);

    useEffect(() => {
        if (!canvasCtx && outputCanvasRef.current) {
            setCanvasCtx(outputCanvasRef.current.getContext('2d'));
        }
    }, [canvasCtx, outputCanvasRef]);

    return (
        <>
            <SessionReplayCanvasWrapper width={canvasDimensions?.width || 540} height={canvasDimensions?.height || 960}>
                <canvas
                    ref={outputCanvasRef}
                    width={canvasDimensions?.width || 540}
                    height={canvasDimensions?.height || 960}
                />
            </SessionReplayCanvasWrapper>
        </>
    );
};
