import React, { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Products } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { CreateUpdatePatientDto, Location, PatientRecord } from '@/common/types';
import { getStringEnumKeyByValue } from '@/common/utils';
import { FormAutoCompleteField, FormTextField, SubmitCancelButtons } from '@/components/common/Form/Form';
import { ServerError } from '@/components/common/Form/styles';
import { resultIsError } from '@/services/HttpService';
import { GaitEventsAddPatientFormWrapper } from './styles';

type CreateGaitEventPatientDto = {
    firstName: string;
    lastName: string;
    location: Location | null;
    gaitUid: string;
};

const shape = {
    location: yup.object().required('Location is required').nullable(),
    gaitUid: yup.string().required(),
};

interface GaitEventsAddPatientFormProps {
    setSelectedLocationId: Dispatch<SetStateAction<number | undefined>>;
    addedPatientCount: number;
    setAddedPatientCount: Dispatch<SetStateAction<number | undefined>>;
}

export const GaitEventsAddPatientForm = (props: GaitEventsAddPatientFormProps) => {
    const { setSelectedLocationId, addedPatientCount, setAddedPatientCount } = props;
    const { httpService } = useHttpContext();
    const [locations, setLocations] = useState<Location[]>([]);
    const [serverError, setServerError] = useState<string | null>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { successToast, errorToast } = useToast();

    const defaultValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        location: null,
        gaitUid: '',
    };
    const schema = yup.object().shape(shape);

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        resetField,
        watch,
    } = useForm<CreateGaitEventPatientDto>({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const locationField = watch('location');

    useEffect(() => {
        setSelectedLocationId(locationField?.id);
    }, [locationField]);

    const getSetLocations = async () => {
        const res = await httpService.getLocations();
        if (!res || resultIsError(res)) {
            errorToast('Could not get locations.');
            return;
        }
        setLocations(res);
    };

    useEffect(() => {
        (async () => {
            await getSetLocations();
        })();
    }, []);

    const transformData = async (dto: CreateGaitEventPatientDto): Promise<CreateUpdatePatientDto> => {
        const data = dto;
        return {
            firstName: 'Resident',
            lastName: data.gaitUid,
            locationId: data.location ? data.location.id : undefined,
            products: [getStringEnumKeyByValue(Products, Products.GAIT)],
            gaitUid: data.gaitUid,
        } as unknown as CreateUpdatePatientDto;
    };

    const addPatient = async (data: CreateGaitEventPatientDto): Promise<PatientRecord | undefined> => {
        setServerError(null);
        setIsSubmitting(true);
        const patientDto = await transformData(data);
        const result = await httpService.addNewPatient(patientDto);
        setIsSubmitting(false);
        if (resultIsError(result)) {
            setServerError(result.message);
            return;
        } else {
            successToast(`Patient added.`);
            return result;
        }
    };

    const save = async (data: CreateGaitEventPatientDto) => {
        const result = await addPatient(data);
        if (result) {
            resetField('gaitUid');
            setAddedPatientCount(addedPatientCount + 1);
        }
    };

    return (
        <GaitEventsAddPatientFormWrapper>
            <form noValidate data-cy="add-patient-form">
                <FormAutoCompleteField
                    control={control}
                    errors={errors}
                    name="location"
                    label="Location"
                    options={locations}
                    optionLabel={(location) => location.name}
                    required
                />
                <br />
                <FormTextField
                    name="gaitUid"
                    label="Unique Gait ID"
                    control={control}
                    errors={errors}
                    required={false}
                />
                <ServerError>{serverError || '\u00a0'}</ServerError>
                <div style={{ padding: 24 }}>
                    <SubmitCancelButtons
                        submitFunction={handleSubmit(save)}
                        cancelFunction={() => {
                            reset();
                        }}
                    />
                </div>
            </form>
        </GaitEventsAddPatientFormWrapper>
    );
};
