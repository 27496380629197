import React, { useEffect, useState } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useRecoilValue } from 'recoil';
import { Products } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useMuiUtils } from '@/common/hooks/MuiUtils';
import { useToast } from '@/common/hooks/useToast';
import { ScanSessionAdminRow } from '@/common/types';
import { DateRangeActions } from '@/components/common/DateRangeActions/DateRangeActions';
import { CalendarIcon } from '@/components/common/Icons/CalendarIcon';
import { SessionReplayAction } from '@/components/common/Pose/SessionReplayAction';
import { TableActionsWrapper } from '@/components/common/Table/styles';
import { DateRangeState } from '@/recoil/atoms/dateRangeAtom';
import { resultIsError } from '@/services/HttpService';
import { AdminScanSearch } from './AdminScanSearch';
import { AdminScanTable } from './AdminScanTable';
import { ScanDetailsAction } from './ScanDetailsAction';
import { ScanSessionPoseLogAction } from './ScanSessionPoseLogAction';
import { ScanSessionReplay } from './ScanSessionReplay';
import { ScanUUIDDisplay } from './ScanUUIDDisplay';
import { AdminDateRangeWrapper } from './styles';

export const AdminScanTableContainer = () => {
    const { httpService } = useHttpContext();
    const { errorToast } = useToast();
    const { palette } = useMuiUtils();
    const [rows, setRows] = useState<ScanSessionAdminRow[]>([]);
    const [originalRows, setOriginalRows] = useState<ScanSessionAdminRow[]>([]);
    const [searchTerm, setSearchTerm] = useState<string | null>();
    const [isLoading, setIsLoading] = useState(false);
    const dateRange = useRecoilValue(DateRangeState);

    const getSessions = async () => {
        setIsLoading(true);
        const start = dateRange.startDate.toISOString();
        const end = dateRange.endDate.toISOString();
        const res = await httpService.getScanSessions(start, end);

        if (!res || resultIsError(res)) {
            errorToast(`Could load scan sessions. ${res?.message || ''}`);
        } else {
            const resultRows = res.map(
                ({
                    id,
                    createdDate,
                    updatedDate,
                    sessionUUID,
                    session,
                    location,
                    practitioner,
                    organization,
                    artifacts,
                    participant,
                }) => {
                    return {
                        id,
                        createdDate,
                        updatedDate,
                        sessionUUID,
                        session,
                        locationName: location?.name || '',
                        practitionerName: practitioner ? `${practitioner.firstName} ${practitioner.lastName}` : '',
                        organizationName: organization?.name || '',
                        action: { sessionUUID, artifacts },
                        participantDetails: {
                            uid: (participant && participant.uid) || '',
                            archivedDate: (participant && participant.isArchived && participant.archivedDate) || null,
                        },
                    };
                },
            );
            setOriginalRows(resultRows);
            setRows(resultRows);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        (async () => {
            await getSessions();
        })();
    }, [dateRange]);

    useEffect(() => {
        if (!searchTerm) {
            setRows(originalRows);
            return;
        }
        setRows(
            originalRows.filter((row) => {
                const sst = searchTerm.toLowerCase();
                const fullString = (
                    (row.sessionUUID || '') +
                    (row.organizationName || '') +
                    (row.practitionerName || '') +
                    (row.locationName || '') +
                    (row.session?.data?.assessmentId || '') +
                    (row?.participantDetails?.uid || '') +
                    (row?.participantDetails?.archivedDate || '')
                ).toLowerCase();
                return fullString.includes(sst);
            }),
        );
    }, [searchTerm, originalRows]);

    const columnStructure = [
        {
            Header: 'Organization',
            accessor: 'organizationName',
            width: '15%',
            Cell: ({ value }) => <>{value}</>,
        },
        {
            Header: 'Provider',
            accessor: 'practitionerName',
            width: '15%',
            Cell: ({ value }) => <>{value}</>,
        },
        {
            Header: 'Created',
            accessor: 'createdDate',
            width: '10%',
            Cell: ({ value }) => (
                <>{value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS) : ''}</>
            ),
        },
        {
            Header: 'UUID',
            accessor: 'sessionUUID',
            width: '10%',
            Cell: ({ value }) => <ScanUUIDDisplay uuid={value} />,
        },
        {
            Header: 'UID',
            accessor: 'participantDetails',
            width: '10%',
            Cell: ({ value }) => (
                <>
                    <Typography>{value.uid}</Typography>
                    {value.archivedDate && (
                        <Typography variant="subtitle2">
                            <Tooltip
                                title={`Archived on: ${DateTime.fromISO(value.archivedDate).toLocaleString(
                                    DateTime.DATETIME_SHORT_WITH_SECONDS,
                                )}`}
                            >
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    Archived
                                    <Box style={{ color: palette.info[500], display: 'flex', marginLeft: 4 }}>
                                        <CalendarIcon />
                                    </Box>
                                </Box>
                            </Tooltip>
                        </Typography>
                    )}
                </>
            ),
        },
        {
            Header: 'Action',
            accessor: 'action',
            width: '10%',
            Cell: ({ value: { sessionUUID, artifacts } }) =>
                artifacts.includes('pose-log') ? (
                    <TableActionsWrapper>
                        <SessionReplayAction product={Products.SCAN} sessionUUID={sessionUUID}>
                            <ScanSessionReplay sessionUUID={sessionUUID} />
                        </SessionReplayAction>
                        <ScanSessionPoseLogAction sessionUUID={sessionUUID} />
                    </TableActionsWrapper>
                ) : (
                    'No pose logs available'
                ),
        },

        {
            Header: 'Session',
            accessor: 'details',
            width: '10%',
            Cell: ({ row }) => (
                <TableActionsWrapper>
                    <ScanDetailsAction row={row.original as ScanSessionAdminRow} />
                </TableActionsWrapper>
            ),
        },
    ];

    return (
        <>
            <AdminDateRangeWrapper>
                <DateRangeActions />
            </AdminDateRangeWrapper>
            <AdminScanSearch setSearchTerm={setSearchTerm} />
            <AdminScanTable columnStructure={columnStructure} data={rows} isLoading={isLoading} />
        </>
    );
};
