import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { HealthPatientStatus, RecordType } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { Session, TimeSpan, PatientRecord } from '@/common/types';
import { getStartAndEndOfDateInMillis, groupBySessionDate, isTodayString } from '@/common/utils';
import { getStringEnumKeyByValue } from '@/common/utils';
import { ResendInvite } from '@/components/Health/HealthPatientActions/ResendInvite/ResendInvite';
import { EmptyData } from '@/components/common/EmptyData/EmptyData';
import { hepState } from '@/recoil/atoms/hepAtom';
import { DateRangeSelector } from '@/recoil/selectors/dateRangeSelector';
import { resultIsError } from '@/services/HttpService';
import { PatientSessionsList } from './PatientSessionsList';
import { PatientSessionSectionWrapper, PatientSessionDateTitle } from './styles';

interface Props {
    sessions: Session[] | undefined;
    timeSpan: TimeSpan;
    patientRecord: PatientRecord;
    inModal?: boolean;
}

export const PatientSessionsListContainer = (props: Props) => {
    const { sessions, timeSpan, patientRecord, inModal = false } = props;
    const { httpService } = useHttpContext();
    const [hepDict, setHEPDict] = useRecoilState(hepState);
    const { startDateMillis, endDateMillis } = useRecoilValue(DateRangeSelector);
    const [patientHEPIds, setPatientHEPIds] = useState<Set<number>>();
    const sessionsByDate = sessions && groupBySessionDate(sessions);
    const [sessionSectionsData, setSessionSectionData] = useState<string[]>([]);

    const shouldResend =
        patientRecord.productData?.health?.status ===
        getStringEnumKeyByValue(HealthPatientStatus, HealthPatientStatus.NO_ACCOUNT);

    const getSessionsData = () => {
        const sessionData: string[] = [];
        sessionsByDate &&
            Object.keys(sessionsByDate).map((dateString) => {
                const processedDate = getStartAndEndOfDateInMillis(dateString);

                if (processedDate.startDate >= startDateMillis && processedDate.endDate <= endDateMillis) {
                    sessionData.push(dateString);
                }
            });
        return sessionData;
    };

    useEffect(() => {
        const sessionsData = getSessionsData();
        setSessionSectionData(sessionsData);
    }, [timeSpan, sessions]);

    const createPatientHepList = (sessions: Session[] | undefined) => {
        // Create an object of Program IDs from each session
        const hepIds = new Set<number>();
        if (!sessions) return hepIds;
        Object.values(sessions).map((session) => {
            if (session.session?.hepId) {
                hepIds.add(session.session.hepId);
            }
        });
        return hepIds;
    };

    const fetchHeps = async () => {
        // Fetch HEPs from API if they are not already in the state
        let newHepDict = { ...hepDict };
        if (patientHEPIds) {
            await Promise.all(
                Array.from(patientHEPIds).map(async (hepId) => {
                    const fetchedHEP = newHepDict[hepId]
                        ? newHepDict[hepId]
                        : await httpService.getHealthHEP(Number(hepId));
                    if (resultIsError(fetchedHEP)) {
                        return;
                    }
                    newHepDict = { ...newHepDict, [hepId]: fetchedHEP };
                }),
            );
        }

        return newHepDict;
    };

    useEffect(() => {
        const retrievedHEPIds = createPatientHepList(sessions);
        setPatientHEPIds(retrievedHEPIds);
    }, []);

    useEffect(() => {
        if (patientHEPIds) {
            (async () => {
                const retrievedHEPs = await fetchHeps();
                setHEPDict(retrievedHEPs);
            })();
        }
    }, [patientHEPIds]);

    return (
        <>
            {sessions && sessions.length <= 0 ? (
                <EmptyData
                    title="No Exer Health use"
                    body="Activity will appear here when exercises are completed."
                    cta={
                        shouldResend ? (
                            <ResendInvite
                                recordId={patientRecord.id}
                                name={patientRecord.patient.firstName}
                                recordType={RecordType.PATIENT}
                                buttonType="button"
                            />
                        ) : undefined
                    }
                />
            ) : sessionsByDate && sessionSectionsData.length > 0 ? (
                <>
                    {sessionSectionsData.map((dateString, i) => {
                        return (
                            <PatientSessionSectionWrapper
                                key={dateString}
                                data-cy="patient-health-session"
                                $inModal={inModal}
                            >
                                <PatientSessionDateTitle
                                    variant="caption"
                                    fontWeight="400"
                                    style={{ ...(inModal && { fontSize: '1rem' }) }}
                                >
                                    {isTodayString(dateString)}
                                    {dateString}
                                </PatientSessionDateTitle>
                                <PatientSessionsList sessions={sessionsByDate[dateString]} />
                            </PatientSessionSectionWrapper>
                        );
                    })}
                </>
            ) : (
                <EmptyData
                    title="No activity completed in this date range"
                    body="Your patient has not done any activity in this date range. Change the date to view your patient's activity."
                />
            )}
        </>
    );
};
