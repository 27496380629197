import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Products } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { Organization } from '@/common/types';
import { getStringEnumKeyByValue } from '@/common/utils';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { Button } from '@/components/common/button/button';
import { resultIsError } from '@/services/HttpService';
import { RouteContentWrapper } from '../../styles';
import { ResetOverrideButtonWrapper, SettingsInputWrapper } from './styles';

export const AdminSettingsRoute = () => {
    const { user } = useExerUser();
    const { httpService } = useHttpContext();
    const [organizations, setOrganizations] = useState<Organization[]>();
    const [selectedOrg, setSelectedOrg] = useState<number | null>();
    const [ready, setReady] = useState(false);
    const { errorToast, successToast } = useToast();

    const getOrganizations = async () => {
        const orgRes = await httpService.getOrganizations();
        if (resultIsError(orgRes)) {
            errorToast('Could not get organizations.');
            return;
        }
        setOrganizations(
            orgRes.filter((org) => {
                return (
                    org.products.includes(getStringEnumKeyByValue(Products, Products.SCAN)) ||
                    org.id === user.organizationId
                );
            }),
        );

        const smooRes = await httpService.adminGetScanModulesOrganizationOverride();
        if (resultIsError(smooRes)) {
            errorToast('Could not get scan modules org override.');
            return;
        }
        setSelectedOrg(smooRes.organizationId || null);
    };

    useEffect(() => {
        (async () => {
            await getOrganizations();
        })();
    }, [user.organizationId]);

    useEffect(() => {
        if (!selectedOrg) {
            user.organizationId && setSelectedOrg(user.organizationId);
        } else {
            setReady(true);
        }
    }, [user.organizationId, selectedOrg]);

    const handleChange = async (event: SelectChangeEvent<any>) => {
        const res = await httpService.adminUpdateScanModulesOrganizationOverride(event.target.value);
        if (resultIsError(res)) {
            errorToast('Could not set override organization.');
            return;
        }
        setSelectedOrg(event.target.value);
        successToast('Override organization set.');
    };

    const handleResetClick = async () => {
        const res = await httpService.adminUpdateScanModulesOrganizationOverride(user.organizationId);
        if (resultIsError(res)) {
            errorToast('Could not reset override.');
            return;
        }
        setSelectedOrg(user.organizationId);
        successToast('Override reset.');
    };

    return (
        <RouteContentWrapper>
            <PageTitle title="Exer Admin User Settings" />

            <h3>Scan Modules Override</h3>
            <p>
                Updating the organization below will serve that organization’s modules to the Scan client when you log
                in with your Exer Admin user. Session data will still be saved to the organization you belong to.
            </p>
            <SettingsInputWrapper>
                <FormControl fullWidth>
                    <InputLabel id="scan-modules-organization-override-label">Organization to Emulate</InputLabel>
                    {ready ? (
                        <>
                            <Select
                                labelId="scan-modules-organization-override-label"
                                id="scan-modules-organization-override"
                                value={selectedOrg}
                                label="Organization to Emulate"
                                onChange={handleChange}
                            >
                                {organizations &&
                                    organizations.map((o) => (
                                        <MenuItem key={o.id} value={+o.id}>
                                            {o.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                            {selectedOrg !== user.organizationId ? (
                                <ResetOverrideButtonWrapper>
                                    <Button onClick={handleResetClick} variant="primary">
                                        Reset to My Organization
                                    </Button>
                                </ResetOverrideButtonWrapper>
                            ) : null}
                        </>
                    ) : (
                        <LoadingSpinner />
                    )}
                </FormControl>
            </SettingsInputWrapper>
        </RouteContentWrapper>
    );
};
