import React, { SyntheticEvent, useState } from 'react';
import { Close } from '@mui/icons-material';
import { useSetRecoilState } from 'recoil';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { mixpanel } from '@/common/mixpanel';
import { Program, TemplateOrHEP } from '@/common/types';
import { HEPBuilderModal } from '@/components/Health/HEPBuilder/HepBuilderModal/HEPBuilderModal';
import { BuilderActions } from '@/components/Health/HEPBuilder/state/HEPBuilderContext';
import { PatientHEPDrawer } from '@/components/Patients/Patient/PatientHEP/PatientHEPDrawer/PatientHEPDrawer';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { Modal } from '@/components/common/Modal/Modal';
import { ModalCloseButton } from '@/components/common/Modal/styles';
import { Center } from '@/components/common/styles';
import { useHEPContext } from '@/components/context/hooks/HEPContext';
import { patientHEPDrawerState } from '@/recoil/atoms/patientHEPDrawer';
import { resultIsError } from '@/services/HttpService';
import { usePatientContext } from '../context/hooks/PatientContext';
import { PatientHEPMenu } from './PatientHEPMenu';
import { HEPPrintModal } from './PrintView/HEPPrintModal';
import { PatientHEPMenuContainerWrapper } from './styles';

export const PatientHEPMenuContainer = () => {
    const { patientRecord } = usePatientContext();
    const { program, setHEP } = useHEPContext();
    const { httpService } = useHttpContext();
    const setDrawerActive = useSetRecoilState(patientHEPDrawerState);
    const { errorToast } = useToast();
    const [unassignDialogOpen, setUnassignDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [printDialogOpen, setPrintDialogOpen] = useState(false);

    const closeDrawer = () => {
        setDrawerActive({ drawerIsActive: false });
    };

    const handleEditHEP = () => {
        closeDrawer();
        setEditDialogOpen(true);
    };

    const handleCloseUnassignDialog = () => {
        setUnassignDialogOpen(false);
    };

    const handleHEPBuilderModalClose = (hepOrEvent: Program | SyntheticEvent | null) => {
        // TODO de-uglify this
        // @ts-ignore
        const program = !hepOrEvent || hepOrEvent?.currentTarget ? undefined : (hepOrEvent as Program);
        if (program) {
            setHEP(program);
        }
        setEditDialogOpen(false);
        closeDrawer();
    };
    const unassignHEP = async () => {
        if (program) {
            const result = await httpService.deleteHealthHEPAssignment(program.id, patientRecord.id);
            if (resultIsError(result)) {
                errorToast('The Program could not be unassigned.');
            } else {
                setHEP(null);
                mixpanel.track('Unassigned Patient from Program');
            }
            setUnassignDialogOpen(false);
        }
    };

    const handlePrintDialog = () => {
        setPrintDialogOpen(true);
    };

    const handleClosePrintDialog = () => {
        setPrintDialogOpen(false);
    };

    return (
        <PatientHEPMenuContainerWrapper>
            <PatientHEPMenu
                handleEditHEP={handleEditHEP}
                setDrawerActive={setDrawerActive}
                setUnassignDialogOpen={setUnassignDialogOpen}
                handlePrint={handlePrintDialog}
            />
            {program && (
                <>
                    <Modal
                        open={unassignDialogOpen}
                        onClose={handleCloseUnassignDialog}
                        title={program.name}
                        fullWidth={true}
                        maxWidth={'lg'}
                    >
                        <Center>
                            <p>
                                Are you sure you want to unassign {patientRecord.patient?.firstName}{' '}
                                {patientRecord.patient?.lastName} from {program?.name}?
                            </p>
                            <p>{'\u00a0'}</p>
                        </Center>
                        <SubmitCancelButtons
                            submitFunction={unassignHEP}
                            cancelFunction={handleCloseUnassignDialog}
                            submitText="Unassign"
                        />
                        <ModalCloseButton onClick={handleCloseUnassignDialog}>
                            <Close />
                        </ModalCloseButton>
                    </Modal>
                    <PatientHEPDrawer
                        program={program}
                        patientRecord={patientRecord}
                        handleSubmit={handleEditHEP}
                        closeDrawer={closeDrawer}
                    />
                    <HEPBuilderModal
                        open={editDialogOpen}
                        onClose={handleHEPBuilderModalClose}
                        existingTemplateOrHEP={program}
                        isTemplateOrHEP={TemplateOrHEP.Program}
                        patientRecord={patientRecord}
                        actionType={BuilderActions.EDIT_HEP}
                    />
                    <HEPPrintModal
                        open={printDialogOpen}
                        program={program}
                        patientRecord={patientRecord}
                        onClose={handleClosePrintDialog}
                    />
                </>
            )}
        </PatientHEPMenuContainerWrapper>
    );
};
