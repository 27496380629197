import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import { useRecoilState } from 'recoil';
import { ProviderRoles } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { Location } from '@/common/types';
import { DropdownInputIcon } from '@/components/common/Form/styles';
import { usePaginationContext } from '@/components/context/hooks/PaginationContext';
import { globalFilterState } from '@/recoil/atoms/globalSearchAtom';
import { RTMLoadingStatus, RTMState } from '@/recoil/atoms/rtmAtom';
import { resultIsError } from '@/services/HttpService';
import { LocationAutocomplete, LocationFilterWrapper } from './styles';

export const LOCATION_FILTER_ID = 'locationId';

export const LocationFilter = () => {
    const { httpService } = useHttpContext();
    const { user, getRole } = useExerUser();
    const [rtmState, setRtmState] = useRecoilState(RTMState);
    const [globalFilters, setGlobalFilters] = useRecoilState(globalFilterState);
    const [locations, setLocations] = useState<Location[]>([]);
    const [userLocations, setUserLocations] = useState<Location[]>();
    const [location, setLocation] = useState<Location | null>(null);
    const { errorToast } = useToast();
    const { page, setPage } = usePaginationContext();

    useEffect(() => {
        (async () => {
            const res = await httpService.getLocations();
            if (resultIsError(res)) {
                errorToast('Unable to get locations');
                return;
            }
            setLocations(res);
        })();
    }, []);

    useEffect(() => {
        if (getRole() === ProviderRoles.PORTAL_PRACTITIONER) {
            setUserLocations(user.locations);
        }
    }, [user]);

    const handleChange = (_, item) => {
        if (page !== 1) {
            setPage(1);
        }
        setLocation(item);
        if (item) {
            setRtmState({
                ...rtmState,
                filters: { ...rtmState.filters, locationId: item.id },
                loadCycles: RTMLoadingStatus.RELOAD,
            });
            setGlobalFilters({
                ...globalFilters,
                locationId: item.id,
            });
        } else {
            setRtmState({
                ...rtmState,
                filters: { ...rtmState.filters, locationId: null },
                loadCycles: RTMLoadingStatus.RELOAD,
            });
            setGlobalFilters({
                ...globalFilters,
                locationId: null,
            });
        }
    };

    useEffect(() => {
        if (globalFilters.locationId) {
            setLocation(locations?.find((loc) => loc.id === globalFilters.locationId) || null);
        } else {
            setLocation(null);
        }
    }, [locations, globalFilters]);

    const locationOptions = userLocations || locations;

    return (
        <LocationFilterWrapper>
            <LocationAutocomplete
                onChange={handleChange}
                value={location}
                options={locationOptions}
                getOptionLabel={(location: any) => location?.name}
                isOptionEqualToValue={(option: any, value: any) =>
                    value === undefined || value === '' || option.id === value.id
                }
                popupIcon={<DropdownInputIcon />}
                renderInput={(params) => {
                    return (
                        <>
                            <TextField {...params} label="Locations" margin="none" variant="outlined" />
                        </>
                    );
                }}
            />
        </LocationFilterWrapper>
    );
};
