import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import { addDays, format } from 'date-fns';
import { Calendar } from 'react-date-range';
import { useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { BodyPart, Products, SideOfBody } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { PatientRecord, PractitionerOption, Location } from '@/common/types';
import {
    getPractitionerOptions,
    getSideOfBodyChoices,
    getStringEnumKeyByValue,
    productsIncludesHealth,
    sleep,
} from '@/common/utils';
import { CalendarWrapper } from '@/components/common/DateRangeActions/styles';
import { Dialog, DialogContent, DialogContentWrapper } from '@/components/common/Dialog/styles';
import { FormAutoCompleteField, FormRadioField, SubmitCancelButtons } from '@/components/common/Form/Form';
import { NewPocIcon } from '@/components/common/Icons/DischargeIcon';
import { Button } from '@/components/common/button/button';
import { resultIsError } from '@/services/HttpService';
import { PortalEvent } from '@/services/events/const';
import eventService from '@/services/events/eventService';
import { InfoTextContainer } from '../DischargeAction/styles';
import { ProductSelection } from './ProductSelection';
import { CalenderContainer, InfoText } from './styles';

interface Props {
    patientRecord: PatientRecord;
    lastEndDate: Date;
    handleClose?: (e) => void;
}

const newPocShape = {
    location: yup.object().required('Location is required'),
    primaryProvider: yup.object().nullable(),
};

const healthPocShape = {
    injuredSideOfBody: yup.string().required('Involved side of body is required'),
    injuredBodyPart: yup.string().required('Involved body part is required'),
};

type NewPlanOfCareDto = {
    location: Location;
    injuredSideOfBody: string;
    injuredBodyPart: string;
    primaryProvider: PractitionerOption | null;
};

export const NewPlanOfCareAction = (props: Props) => {
    const { patientRecord, handleClose, lastEndDate } = props;
    const { location, productData, practitioner, products, id, patient } = patientRecord;
    const { httpService } = useHttpContext();
    const [dialogOpen, setDialogOpen] = useState(false);
    const { successToast, errorToast } = useToast();
    const [locations, setLocations] = useState<Location[]>();
    const [practitioners, setPractitioners] = useState<PractitionerOption[]>();
    const [currentPractitioner, setCurrentPractitioner] = useState<PractitionerOption | undefined>(
        practitioner
            ? {
                  id: practitioner.id,
                  name: `${practitioner.firstName} ${practitioner.lastName}`,
              }
            : undefined,
    );
    const [defaultProducts, setDefaultProducts] = useState<string[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [productsConfirmed, setProductsConfirmed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [includesHealth, setIncludesHealth] = useState<boolean>(
        products.includes(getStringEnumKeyByValue(Products, Products.HEALTH)!),
    );
    const [startDate, setStartDate] = useState(new Date());

    const handleProductSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = getStringEnumKeyByValue(Products, e.target.value as Products);
        const products = selectedProducts?.includes(val!)
            ? selectedProducts?.filter((existing) => existing !== val)
            : [...(selectedProducts ?? []), val!];
        setSelectedProducts(products);
    };

    const confirmProductChoices = () => {
        setProductsConfirmed(true);
    };

    const handleCalendarSelection = (item) => {
        setStartDate(item);
    };

    const shape = {
        ...newPocShape,
        ...(includesHealth && { ...healthPocShape }),
    };

    const schema = yup.object().shape(shape);

    const { handleSubmit, control, reset } = useForm<NewPlanOfCareDto>({
        defaultValues: {
            location,
            injuredSideOfBody:
                productData?.health?.injuredSideOfBody === null
                    ? SideOfBody.N_A
                    : productData?.health?.injuredSideOfBody,
            injuredBodyPart: productData?.health?.injuredBodyPart,
            primaryProvider: practitioner
                ? {
                      id: practitioner.id,
                      name: `${practitioner.firstName} ${practitioner.lastName}`,
                  }
                : null,
        },
        resolver: yupResolver(schema),
    });
    const { errors } = useFormState({
        control,
    });

    const handleCloseDialog = async (e) => {
        setDialogOpen(false);
        handleClose && handleClose(e);
        reset();
        await sleep(500);
        setDefaultProducts(products);
        setSelectedProducts(products);
        setProductsConfirmed(false);
    };

    const handleClick = () => {
        setDialogOpen(true);
    };

    const handleNewPlanOfCare = async (data: NewPlanOfCareDto) => {
        const injuredSideOfBody =
            !data.injuredSideOfBody || data.injuredSideOfBody === SideOfBody.N_A ? null : data.injuredSideOfBody;

        const updatePatientBody = {
            ...(data.location?.id !== location?.id && { location: data.location }),
            ...(data.primaryProvider?.id !== practitioner?.id && { primaryPractitionerId: data.primaryProvider?.id }),
        };

        const planOfCareBody = {
            injuredSideOfBody: includesHealth ? injuredSideOfBody : null,
            injuredBodyPart: includesHealth ? data.injuredBodyPart : null,
            start: format(startDate, 'yyyy-MM-dd'),
        };

        if (Object.keys(updatePatientBody).length !== 0) {
            const result = await httpService.updatePatientRecord(id, { ...patientRecord, ...updatePatientBody });
            if (resultIsError(result)) {
                errorToast(result.message);
            }
        }
        setLoading(true);
        const result = await httpService.newPlanOfCare(patientRecord.id, planOfCareBody);
        setLoading(false);

        if (resultIsError(result)) {
            errorToast(result.message);
        } else {
            successToast(`Successfully started a new Plan of Care for ${patient.firstName} ${patient.lastName}`);
            handleCloseDialog(data);
            await sleep(500);
            eventService.dispatch(PortalEvent.UPDATE_PATIENT, null);
        }
    };

    const onSubmit = () => {
        handleSubmit(handleNewPlanOfCare)();
    };

    const getSetLocations = async () => {
        const res = await httpService.getLocations();
        if (!res || resultIsError(res)) {
            errorToast('Could not get locations.');
            return;
        }
        setLocations(res);
    };

    const getSetPractitioners = async () => {
        const res = await httpService.getPractitioners();
        if (!res || resultIsError(res)) {
            errorToast('Could not get providers.');
            return;
        }
        const uniquePractitioners = getPractitionerOptions(res, setCurrentPractitioner, currentPractitioner);
        setPractitioners(uniquePractitioners);
    };

    useEffect(() => {
        (async () => {
            await getSetLocations();
            await getSetPractitioners();
        })();
    }, []);

    useEffect(() => {
        setDefaultProducts(products);
        setSelectedProducts(products);
    }, [products]);

    useEffect(() => {
        setIncludesHealth(selectedProducts.includes(getStringEnumKeyByValue(Products, Products.HEALTH)!));
    }, [selectedProducts]);

    return (
        <>
            <Button onClick={handleClick} variant="text" size="small" startIcon={<NewPocIcon />}>
                New POC
            </Button>
            <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth={true} maxWidth={'sm'}>
                <DialogContent>
                    <DialogContentWrapper>
                        <h2>New Plan of Care</h2>
                        {!productsConfirmed ? (
                            <ProductSelection
                                products={selectedProducts}
                                handleProductSelect={handleProductSelect}
                                defaultProducts={defaultProducts}
                                handleCancel={handleCloseDialog}
                                confirmProductChoices={confirmProductChoices}
                            />
                        ) : (
                            <>
                                <CalenderContainer>
                                    <CalendarWrapper>
                                        <Calendar
                                            date={startDate}
                                            onChange={handleCalendarSelection}
                                            months={1}
                                            direction="horizontal"
                                            monthDisplayFormat="MMMM yyyy"
                                            minDate={lastEndDate ? addDays(lastEndDate, 1) : undefined}
                                            maxDate={new Date()}
                                        />
                                    </CalendarWrapper>
                                </CalenderContainer>
                                <InfoTextContainer>
                                    <Typography variant="body1">
                                        {`${patient.firstName}'s plan of care will start on `}
                                        <b>
                                            {startDate.toLocaleDateString('en-us', {
                                                weekday: 'short',
                                                month: 'short',
                                                day: 'numeric',
                                            })}
                                        </b>
                                    </Typography>
                                </InfoTextContainer>
                                {locations && (
                                    <FormAutoCompleteField
                                        control={control}
                                        errors={errors}
                                        name="location"
                                        label="Location"
                                        options={locations}
                                        optionLabel={(location) => location.name}
                                    />
                                )}
                                <FormAutoCompleteField
                                    control={control}
                                    errors={errors}
                                    name="primaryProvider"
                                    label="Primary Provider"
                                    options={practitioners || []}
                                    optionLabel={(practitioner) => practitioner?.name}
                                    clearIcon={null}
                                />
                                {includesHealth && (
                                    <>
                                        <FormRadioField
                                            control={control}
                                            errors={errors}
                                            name="injuredSideOfBody"
                                            label="Involved Side"
                                            required={productsIncludesHealth(products)}
                                            choices={getSideOfBodyChoices()}
                                        />
                                        <FormRadioField
                                            control={control}
                                            errors={errors}
                                            name="injuredBodyPart"
                                            label="Involved Body Part"
                                            required={productsIncludesHealth(products)}
                                            choices={[
                                                {
                                                    value: getStringEnumKeyByValue(BodyPart, BodyPart.SHOULDER),
                                                    label: BodyPart.SHOULDER,
                                                },
                                                {
                                                    value: getStringEnumKeyByValue(BodyPart, BodyPart.HIP),
                                                    label: BodyPart.HIP,
                                                },
                                                {
                                                    value: getStringEnumKeyByValue(BodyPart, BodyPart.KNEE),
                                                    label: BodyPart.KNEE,
                                                },
                                                {
                                                    value: getStringEnumKeyByValue(BodyPart, BodyPart.SPINE),
                                                    label: BodyPart.SPINE,
                                                },
                                                {
                                                    value: getStringEnumKeyByValue(BodyPart, BodyPart.MULTIPLE),
                                                    label: BodyPart.MULTIPLE,
                                                },
                                            ]}
                                        />
                                    </>
                                )}
                                <InfoText>
                                    If the patient needs to be assigned a Program or their Program should be updated,
                                    please do so after creation.
                                </InfoText>
                                <SubmitCancelButtons
                                    submitFunction={onSubmit}
                                    cancelFunction={handleCloseDialog}
                                    submitText="Create"
                                    submitDisabled={loading}
                                    loading={loading}
                                />
                            </>
                        )}
                    </DialogContentWrapper>
                </DialogContent>
            </Dialog>
        </>
    );
};
