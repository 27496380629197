import React, { useState, useEffect, useCallback } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { PAGE_SIZE } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { RTMItem, RTMPagedRequestParams } from '@/common/types';
import { PatientBillingEmpty } from '@/components/PatientBilling/PatientBillingEmpty';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { usePaginationContext } from '@/components/context/hooks/PaginationContext';
import { RTMCyclesByMonthState, RTMState as RTMAtomState, RTMLoadingStatus } from '@/recoil/atoms/rtmAtom';
import { RTMSelector } from '@/recoil/selectors/rtmSelector';
import { resultIsError } from '@/services/HttpService';
import { CyclesByMonthTable } from './CyclesByMonthTable';

export const PatientsBillingTableContainer = () => {
    const { httpService } = useHttpContext();
    const { page, sort, setPaginationMeta, setPage, globalSearch } = usePaginationContext();
    const {
        filteredStatusList,
        startDateSelected,
        endDateSelected,
        selectedRTMMonth,
        locationId,
        reloadCyclesByMonth,
    } = useRecoilValue(RTMSelector);
    const { errorToast } = useToast();
    const [RTMCyclesByMonth, setRTMCyclesByMonth] = useRecoilState(RTMCyclesByMonthState);
    const [RTMState, setRTMState] = useRecoilState(RTMAtomState);
    const [hasFetched, setHasFetched] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    const getRequestParams = (): RTMPagedRequestParams => {
        const params = {} as RTMPagedRequestParams;
        params.page = page;
        params.limit = PAGE_SIZE;
        if (globalSearch) {
            params.search = globalSearch;
        }
        if (sort && !!sort.sortKey) {
            params.sortBy = sort.sortKey;
            params.orderBy = sort.dir;
        }
        if (filteredStatusList.length > 0) {
            params.status = filteredStatusList;
        }
        if (startDateSelected) {
            params.start = startDateSelected;
        }
        if (endDateSelected) {
            params.end = endDateSelected;
        }
        if (locationId) {
            params.locationId = `${locationId}`;
        }
        return params;
    };

    const getRTMData = useCallback(async () => {
        setIsEmpty(false);
        const cyclesByMonthRes = await httpService.getRTMCyclesByMonth(getRequestParams(), selectedRTMMonth);
        if (resultIsError(cyclesByMonthRes)) {
            if (page !== 1) {
                setPage(1);
            } else {
                errorToast(`Sorry we're experiencing an issue, please try reloading the page.`);
            }
        } else {
            setRTMCyclesByMonth(cyclesByMonthRes.items as RTMItem[]);

            setPaginationMeta(cyclesByMonthRes.metadata);
        }
        setRTMState({ ...RTMState, loadCycles: RTMLoadingStatus.DONE });
    }, [page, sort, reloadCyclesByMonth, globalSearch]);

    useEffect(() => {
        (async () => {
            await getRTMData().finally(() => {
                setHasFetched(true);
            });
        })();
    }, [getRTMData]);

    return (
        <>
            {!hasFetched ? (
                <LoadingSpinner />
            ) : (
                <>
                    {isEmpty ? (
                        <PatientBillingEmpty
                            title="You haven't assigned any patients yet"
                            body="Add your first patient and manage their RTM billing cycles."
                        />
                    ) : (
                        <CyclesByMonthTable data={RTMCyclesByMonth} />
                    )}
                </>
            )}
        </>
    );
};
