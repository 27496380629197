import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { hepTemplateState } from '@/recoil/atoms/hepTemplatesAtom';
import { resultIsError } from '@/services/HttpService';
import { HEPTemplateDetails } from './HEPTemplateDetails';

interface HEPTemplateDetailsContainerProps {
    hepTemplateId: number;
}

export const HEPTemplateDetailsContainer = (props: HEPTemplateDetailsContainerProps) => {
    const { hepTemplateId } = props;
    const { httpService } = useHttpContext();
    const [hepTemplate, setHEPTemplate] = useRecoilState(hepTemplateState);
    const { errorToast } = useToast();
    useEffect(() => {
        (async () => {
            const template = await httpService.getHEPTemplate(hepTemplateId);
            if (resultIsError(template)) {
                errorToast('Could not get template.');
                return;
            }
            if (template) {
                setHEPTemplate(template);
            }
        })();
    }, []);

    return hepTemplate ? <HEPTemplateDetails hepTemplate={hepTemplate} /> : null;
};
