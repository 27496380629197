import React from 'react';
import { AdminGaitTableContainer } from '@/components/Gait/Admin/AdminGaitTableContainer';
import { PageTitle } from '@/components/common/PageTitle/PageTitle';
import { PaginationProvider } from '@/components/context/PaginationContext';
import { RouteContentWrapper } from '../../styles';

export const AdminGaitRoute = () => {
    return (
        <RouteContentWrapper>
            <PageTitle title="Gait Sessions" />
            <PaginationProvider>
                <AdminGaitTableContainer />
            </PaginationProvider>
        </RouteContentWrapper>
    );
};
