import React, { useState } from 'react';
import { assignUserProps } from '@exerai/react-core';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, TextField, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { AllowedPortalRoles, Paths } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { mixpanel } from '@/common/mixpanel';
import { ROLES } from '@/common/types';
import { refreshUserProfile } from '@/common/utils';
import { AuthForm, AuthLink, AuthPasswordControl } from '@/components/Auth/styles';
import { EyeClosedIcon } from '@/components/common/Icons/EyeClosedIcon';
import { EyeOpenIcon } from '@/components/common/Icons/EyeOpenIcon';
import { PortalEvent } from '@/services/events/const';
import eventService from '@/services/events/eventService';

const schema = yup.object().shape({
    username: yup.string().email('Email must be valid.').required('Email is required.'),
    password: yup.string().required('Password is required.'),
});

type Data = {
    username: string;
    password: string;
};

interface State {
    password: string;
    showPassword: boolean;
}

export const LoginForm = () => {
    const { httpClient, httpService } = useHttpContext();
    const { dispatchUser } = useExerUser();
    const { register, handleSubmit, formState, setError } = useForm({ resolver: yupResolver(schema) });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [values, setValues] = useState<State>({
        password: '',
        showPassword: false,
    });

    const getFrom = (groups: ROLES[]) => {
        const previousLocation = location.state as { from: { pathname: string } };
        if (!groups || !AllowedPortalRoles.some((g) => groups.includes(g))) {
            return { from: { pathname: Paths.userProviderNotFound } };
        }
        if (previousLocation && previousLocation.from?.pathname !== Paths.userProviderNotFound) {
            return previousLocation;
        }
        return { from: { pathname: Paths.dashboard } };
    };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    const { errorToast } = useToast();

    const signIn = async (data: Data) => {
        setLoading(true);
        try {
            const userResponse = await Auth.signIn(data.username, data.password);
            const sius = userResponse.getSignInUserSession();
            mixpanel.identify(sius.idToken.payload.sub);
            assignUserProps(sius, httpClient, dispatchUser);
            const refreshResult = await refreshUserProfile(httpService, dispatchUser);
            if (!refreshResult.success && process.env.ENVIRONMENT !== 'QA') {
                errorToast(refreshResult.errorMsg || `There was an error loading your account.`);
                if (refreshResult.logout) {
                    await Auth.signOut();
                    setLoading(false);
                    return;
                }
            }
            eventService.dispatch(PortalEvent.SIGN_IN_OR_CREATE, null);
            mixpanel.track('Logged in');
            const { from } = getFrom(sius?.idToken?.payload?.['cognito:groups']);
            navigate(from);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setError('password', {
                type: 'manual',
                message: 'There was a problem signing in.  Please check your email and password.',
            });
            mixpanel.track('Failed login');
            return;
        }
    };

    return (
        <>
            <AuthForm data-cy="login-form" onSubmit={handleSubmit(signIn)} noValidate>
                <TextField
                    variant="outlined"
                    {...register('username')}
                    margin="normal"
                    fullWidth
                    id="username"
                    label="Email"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    error={!!formState.errors.username}
                    data-cy="login-email"
                    helperText={formState.errors.username && (formState.errors.username.message as string)}
                />
                <AuthPasswordControl variant="outlined" error={!!formState.errors.password} fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        {...register('password')}
                        required
                        id="password"
                        type={values.showPassword ? 'text' : 'password'}
                        name="password"
                        autoComplete="password"
                        label="Password"
                        data-cy="login-password"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText id="password">
                        {formState.errors.password && (formState.errors.password.message as string)}
                    </FormHelperText>
                </AuthPasswordControl>
                <Box my={3}>
                    <LoadingButton loading={loading} variant="primary" fullWidth type="submit" data-cy="login-submit">
                        Sign In
                    </LoadingButton>
                </Box>
                <AuthLink to={Paths.userForgotPassword}>Forgot Password</AuthLink>
            </AuthForm>
        </>
    );
};
