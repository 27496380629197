export function drawPoint(ctx: CanvasRenderingContext2D, y: number, x: number, r: number, pointColor: string) {
    ctx.beginPath();
    ctx.arc(x, y, r, 0, 2 * Math.PI);
    ctx.fillStyle = 'rgba(0,0,0,0)';
    ctx.lineWidth = 3;
    ctx.strokeStyle = pointColor;
    ctx.stroke();
    ctx.fill();
}

export const drawExerLandmarks = (
    keypoints: any[],
    ctx: CanvasRenderingContext2D,
    scale = 1,
    pointcolor = '#BADA55',
) => {
    keypoints.map((keypoint) => {
        drawPoint(ctx, keypoint[1] * scale, keypoint[0] * scale, 4, pointcolor);
    });
};

const radiansToDegrees = (radians: number) => {
    const pi = Math.PI;
    let deg = Math.floor(radians * (180 / pi));
    if (deg < 0) {
        deg = 360 - Math.abs(deg);
    }
    return deg;
};

export const getAngle = (point1: number[], vertex: number[], point2: number[], complementary = false) => {
    const result =
        Math.atan2(point2[1] - vertex[1], point2[0] - vertex[0]) -
        Math.atan2(point1[1] - vertex[1], point1[0] - vertex[0]);

    const angleInDegrees = radiansToDegrees(result);
    return complementary ? (180 - angleInDegrees > 0 ? 180 - angleInDegrees : 0) : angleInDegrees;
};
