import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { addDays, isFuture, isToday, format } from 'date-fns';
import { Calendar } from 'react-date-range';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { PatientRecord } from '@/common/types';
import { CalendarWrapper } from '@/components/common/DateRangeActions/styles';
import { Dialog, DialogContent, DialogContentWrapper } from '@/components/common/Dialog/styles';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { EditIcon } from '@/components/common/Icons/EditIcon';
import { Button } from '@/components/common/button/button';
import { resultIsError } from '@/services/HttpService';
import { CalenderContainer } from './styles';

type EditPlanOfCareDto = {
    patientRecord: PatientRecord;
    handleClose?: (e) => void;
    setEndDate?: (Date) => void;
    endDate: Date;
};

export const EditPlanOfCareAction = (props: EditPlanOfCareDto) => {
    const { patientRecord, handleClose, setEndDate, endDate } = props;
    const { httpService } = useHttpContext();
    const [dialogOpen, setDialogOpen] = useState(false);
    const { successToast, errorToast } = useToast();
    const [date, setDate] = useState(endDate);

    const handleCloseDialog = (e) => {
        setDialogOpen(false);
        handleClose && handleClose(e);
        setDate(endDate);
    };

    const handleCalendarSelection = (item) => {
        setDate(item);
    };

    const handleClick = () => {
        setDialogOpen(true);
    };

    const handleDischargeArchive = async (e) => {
        setEndDate && setEndDate(date);
        const result = await httpService.dischargePatient(patientRecord.id, format(date, 'yyyy-MM-dd'));
        if (resultIsError(result)) {
            errorToast(result.message);
        } else {
            successToast(
                `Successfully discharged ${patientRecord.patient.firstName} ${patientRecord.patient.lastName}`,
            );

            handleCloseDialog(e);
        }
    };

    return (
        <>
            <Button onClick={handleClick} variant="text" size="small" startIcon={<EditIcon />}>
                Edit POC
            </Button>
            <Dialog open={dialogOpen} onClose={handleCloseDialog} fullWidth={true} maxWidth={'sm'}>
                <DialogContent>
                    <DialogContentWrapper>
                        <h2>Edit Plan of Care</h2>
                        <CalenderContainer>
                            <CalendarWrapper>
                                <Calendar
                                    date={date}
                                    onChange={handleCalendarSelection}
                                    months={1}
                                    direction="horizontal"
                                    monthDisplayFormat="MMMM yyyy"
                                    minDate={new Date()}
                                    maxDate={addDays(new Date(), 28)}
                                />
                            </CalendarWrapper>
                        </CalenderContainer>
                        <div style={{ margin: `0 40px 40px` }}>
                            {(isFuture(date) || isToday(date)) && (
                                <>
                                    <Typography variant="body1">
                                        The patient discharge will occur on{' '}
                                        <b>
                                            {date.toLocaleDateString('en-us', {
                                                weekday: 'short',
                                                month: 'short',
                                                day: 'numeric',
                                            })}
                                        </b>
                                        , after which {patientRecord.patient.firstName} will also be Archived.
                                    </Typography>
                                </>
                            )}
                        </div>

                        <SubmitCancelButtons
                            submitFunction={handleDischargeArchive}
                            cancelFunction={handleCloseDialog}
                            submitText="Save"
                            cancelText="Cancel"
                        />
                    </DialogContentWrapper>
                </DialogContent>
            </Dialog>
        </>
    );
};
