import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DialogActions, FormControlLabel, Switch } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { Maybe, SortableScanModule } from '@/common/types';
import { FormTextField } from '@/components/common/Form/Form';
import { Button } from '@/components/common/button/button';
import { Dialog, DialogContent, DialogTitle } from './styles';

export const scanModuleSchema = yup.object().shape({
    name: yup.string().required('Module Name is required'),
    subtitle: yup.string().required('Subtitle is required'),
    imageName: yup.string().default('Assessment'),
    isLocked: yup.boolean().required('Locked status is required'),
    imageUrl: yup.string().url('Invalid URL format').nullable(),
    assessments: yup.array().nullable().default([]),
});

export const ModuleModal = ({
    open,
    onClose,
    onSave,
    module,
    type,
}: {
    module?: SortableScanModule;
    open: boolean;
    onClose: () => void;
    onSave: (newModule: SortableScanModule, oldModuleName: Maybe<string>) => void;
    type: 'NEW' | 'EDIT';
}) => {
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(scanModuleSchema),
        defaultValues: {
            name: module ? module.name : '',
            subtitle: module ? module.subtitle : '',
            imageName: module ? module.imageName : 'Assessment',
            isLocked: module ? module.isLocked : false,
            imageUrl: null,
            assessments: module ? module.assessments : [],
        },
    });

    useEffect(() => {
        if (module) {
            reset({
                name: module.name,
                subtitle: module.subtitle,
                imageName: module.imageName || 'Assessment',
                isLocked: module.isLocked,
                assessments: module.assessments,
            });
        }
    }, [module, reset]);

    const save = (data) => {
        const newModule = {
            id: data.id || uuidv4(),
            name: data.name,
            subtitle: data.subtitle,
            imageName: data.imageName,
            isLocked: data.isLocked,
            imageUrl: null,
            assessments: module ? module.assessments : [],
        };
        onSave(newModule, module && module.id);
        handleClose();
    };

    const handleClose = () => {
        onClose();
        reset();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{type === 'EDIT' ? 'Edit' : 'Add'} Module</DialogTitle>
            <DialogContent>
                <FormTextField
                    name="name"
                    control={control}
                    errors={errors}
                    autoFocus
                    margin="dense"
                    label="Name"
                    type="text"
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name?.message}
                />
                <FormTextField
                    name="subtitle"
                    control={control}
                    errors={errors}
                    margin="dense"
                    label="Subtitle"
                    type="text"
                    fullWidth
                    error={!!errors.subtitle}
                    helperText={errors.subtitle?.message}
                />
                <FormTextField
                    name="imageName"
                    control={control}
                    errors={errors}
                    margin="dense"
                    label="Image Name"
                    type="text"
                    fullWidth
                    error={!!errors.imageName}
                    helperText={errors.imageName?.message}
                />
                <Controller
                    name="isLocked"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={<Switch {...field} checked={field.value} />}
                            label="Locked Status"
                            labelPlacement="start"
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="tertiary">
                    Cancel
                </Button>
                <Button type="submit" variant="primary" onClick={handleSubmit(save)}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
