import React, { useCallback } from 'react';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { Products } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { PatientRecord } from '@/common/types';
import { getStringEnumKeyByValue, productCanBeAddedToPatientWithoutAdditionalInfo } from '@/common/utils';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { AddIcon } from '@/components/common/Icons/AddIcon';
import { Button } from '@/components/common/button/button';
import { useModalContext } from '@/components/context/hooks/ModalContext';
import { resultIsError } from '@/services/HttpService';
import { PortalEvent } from '@/services/events/const';
import eventService from '@/services/events/eventService';
import { PatientEditDialogContent } from '../PatientActionsDialogContent/PatientEditDialogContent';
import { AddPatientToProductDialogWrapper } from './styles';

interface Props {
    patientRecord: PatientRecord;
    toAdd: Products;
    isCTA?: boolean;
}
export const AddtoProductAction = (props: Props) => {
    const { patientRecord, toAdd, isCTA } = props;
    const { httpService } = useHttpContext();
    const { setDialog, setDialogOpen } = useModalContext();
    const { successToast, errorToast } = useToast();

    const needsNoAdditionalInfo = useCallback((): boolean => {
        return !!toAdd && productCanBeAddedToPatientWithoutAdditionalInfo(patientRecord, toAdd);
    }, [toAdd, patientRecord]);

    const handleClick = () => {
        setDialog(
            'Add Patient to Product',
            <AddPatientToProductDialogWrapper>
                <p>
                    Add {patientRecord.patient?.firstName} {patientRecord.patient?.lastName} to Exer {toAdd}?
                    {!needsNoAdditionalInfo() && (
                        <>
                            <br />
                            You will have to add additional details to proceed.
                        </>
                    )}
                </p>
                <SubmitCancelButtons
                    submitText={needsNoAdditionalInfo() ? 'Add' : 'Next'}
                    submitFunction={needsNoAdditionalInfo() ? handleSubmit : proceedToEdit}
                    cancelFunction={() => setDialogOpen(false)}
                />
            </AddPatientToProductDialogWrapper>,
        );
        setDialogOpen(true);
    };

    const proceedToEdit = async () => {
        const updatedPatientRecord = {
            ...patientRecord,
            products: [...patientRecord.products, getStringEnumKeyByValue(Products, toAdd) as string],
        };
        setDialog(
            `Add ${toAdd} Patient`,
            <PatientEditDialogContent
                patientRecord={updatedPatientRecord}
                handleCancel={() => {
                    setDialogOpen(false);
                }}
                onSuccess={() => {
                    eventService.dispatch(PortalEvent.UPDATE_PATIENT, updatedPatientRecord);
                    setDialogOpen(false);
                }}
            />,
        );
    };

    const handleSubmit = async () => {
        const result = await httpService.updatePatientRecord(patientRecord.id, {
            products: [...patientRecord.products, getStringEnumKeyByValue(Products, toAdd) as string],
        });
        if (resultIsError(result)) {
            errorToast(result.message);
        } else {
            setDialogOpen(false);
            successToast(`Successfully added patient${toAdd ? ` to ${toAdd}` : ''}.`);
            eventService.dispatch(PortalEvent.UPDATE_PATIENT, result);
        }
    };

    return toAdd ? (
        isCTA ? (
            <Button onClick={handleClick} variant="primary">
                <>Add to Exer {toAdd}</>
            </Button>
        ) : (
            <MenuItem onClick={handleClick}>
                <ListItemIcon>
                    <AddIcon />
                </ListItemIcon>
                <ListItemText>Add To {toAdd}</ListItemText>
            </MenuItem>
        )
    ) : null;
};
