import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { OrganizationAdmin, ScanModule, Assessment, SortableScanModule } from '@/common/types';
import { saturateModulesWithAssessments } from '@/common/utils';
import { resultIsError } from '@/services/HttpService';
import { AdminModulesList } from './Modules/ModulesList';

interface AdminOrganizationScanModulesProps {
    organization: OrganizationAdmin;
    submitScanModules: (scanModules: ScanModule[]) => Promise<void>;
}

export const AdminOrganizationScanModules = (props: AdminOrganizationScanModulesProps) => {
    const { httpService } = useHttpContext();
    const { organization, submitScanModules } = props;
    const { errorToast } = useToast();
    const [modules, setModules] = useState<SortableScanModule[]>([]);
    const [assessmentsDict, setAssessmentsDict] = useState<Assessment[]>();

    const getAssessments = async () => {
        const res = await httpService.adminGetAssessments();
        if (!res || resultIsError(res)) {
            errorToast("Couldn't fetch assessments :(");
        } else {
            setAssessmentsDict(res);
        }
    };

    useEffect(() => {
        (async () => {
            await getAssessments();
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (assessmentsDict && organization?.scanModules) {
                try {
                    setModules(saturateModulesWithAssessments(organization.scanModules, assessmentsDict));
                } catch (e) {
                    errorToast('Could not parse organization scan modules.');
                    return;
                }
            }
        })();
    }, [assessmentsDict, organization]);

    return organization ? (
        <>
            <Typography variant="h4">Scan Modules</Typography>
            <Box>
                {assessmentsDict && (
                    <AdminModulesList
                        assessmentsDict={assessmentsDict}
                        scanModules={modules}
                        submitScanModules={submitScanModules}
                    />
                )}
            </Box>
        </>
    ) : null;
};
