import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { validate } from 'uuid';
import { Products } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { SessionReplayAction } from '@/components/common/Pose/SessionReplayAction';
import { TableActionsWrapper } from '@/components/common/Table/styles';
import { resultIsError } from '@/services/HttpService';
import { AdminGaitTable } from './AdminGaitTable';
import { GaitSessionPoseLogAction } from './GaitSessionPoseLogAction';
import { GaitSessionReplay } from './GaitSessionReplay';

interface AdminGaitSessionRow {
    id: number;
    sessionUUID: string;
    capturedDate: string;
    action: string;
}

export const AdminGaitTableContainer = () => {
    const { httpService } = useHttpContext();
    const [rows, setRows] = useState<AdminGaitSessionRow[]>([]);

    const getSessions = async () => {
        const res = await httpService.adminGetGaitSessions(
            DateTime.now().minus({ days: 90 }).toISO(),
            DateTime.now().toISO(),
        );
        if (!res || resultIsError(res)) {
            alert('Couldnt fetch gait sessions :(');
        } else {
            setRows(
                res
                    .map(({ id, capturedDate, sessionUUID, patient }) => {
                        return {
                            id,
                            capturedDate,
                            sessionUUID,
                            patientName: `${patient.firstName} ${patient.lastName}`,
                            action: sessionUUID,
                        };
                    })
                    .filter((row) => {
                        return validate(row.sessionUUID);
                    }),
            );
        }
    };

    useEffect(() => {
        (async () => {
            await getSessions();
        })();
    }, []);

    const columnStructure = [
        {
            Header: 'ID',
            accessor: 'id',
            width: '10%',
            Cell: ({ value }) => <>{value}</>,
        },
        {
            Header: 'Captured Date',
            accessor: 'capturedDate',
            width: '15%',
            Cell: ({ value }) => <>{value}</>,
        },
        {
            Header: 'Patient Name',
            accessor: 'patientName',
            width: '15%',
            Cell: ({ value }) => <>{value}</>,
        },
        {
            Header: 'UUID',
            accessor: 'sessionUUID',
            width: '20%',
            Cell: ({ value }) => <>{value}</>,
        },
        {
            Header: 'Action',
            accessor: 'action',
            width: '10%',
            Cell: ({ value }) => (
                <TableActionsWrapper>
                    <SessionReplayAction product={Products.GAIT} sessionUUID={value}>
                        <GaitSessionReplay sessionUUID={value} />
                    </SessionReplayAction>
                    <GaitSessionPoseLogAction sessionUUID={value} />
                </TableActionsWrapper>
            ),
        },
    ];

    return <AdminGaitTable columnStructure={columnStructure} data={rows} />;
};
