import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, InputLabel, MenuItem } from '@mui/material';
import { Controller, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { ProviderRoles } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { mixpanel } from '@/common/mixpanel';
import { CreatePractitionerDto, Location } from '@/common/types';
import { FormTextField, SaveSaveAnotherCancelButtons } from '@/components/common/Form/Form';
import { FormFieldContainer, ServerError, SuccessMessage } from '@/components/common/Form/styles';
import { resultIsError } from '@/services/HttpService';
import { MultiSelectField } from '../MultiSelectField/MultiSelectField';
import { usePractitionersContext } from '../context/hooks/PractitionerContext';
import { PractitionerRoleSelect, PractitionerForm } from '../styles';

const defaultValues: CreatePractitionerDto = {
    firstName: '',
    lastName: '',
    email: '',
    role: 'PORTAL_PRACTITIONER',
    locationIds: [],
};

interface Props {
    handleCancel: (_: any) => void;
    scrollTarget?: any;
    locations: Location[] | undefined;
}

const schema = yup.object().shape({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup.string().email().required('Email is required'),
    role: yup.string().required('Role is required'),
    locationIds: yup.array().when('role', {
        is: 'PORTAL_PRACTITIONER',
        then: yup.array().min(1, 'Location(s) are required').required('Location(s) are required'),
    }),
});

type AddPractitionDto = Omit<CreatePractitionerDto, 'locationId'> & { locationId?: string | number };

export const AddPractitionerForm = (props: Props) => {
    const { handleCancel, scrollTarget, locations } = props;
    const { httpService } = useHttpContext();
    const { refreshPractitioners } = usePractitionersContext();
    const { successToast } = useToast();
    const [serverError, setServerError] = useState<string>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [selectedLocations, setSelectedLocations] = useState<number[]>([]);

    let addAnother = false;
    const { handleSubmit, control, reset, setValue } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema),
    });

    const { errors } = useFormState({
        control,
    });

    const displaySuccessMessage = () => {
        setShowMessage(true);
        setTimeout(() => {
            setShowMessage(false);
        }, 1000);
    };

    const saveAndAddAnother = async (data: AddPractitionDto) => {
        const locationId = data.locationId === 'none' ? undefined : Number(data.locationId);
        const practitionerDto = { ...data, locationId: locationId } as CreatePractitionerDto;

        addAnother = true;
        const success = await onSubmit(practitionerDto);
        if (success) {
            if (scrollTarget && scrollTarget.current) {
                scrollTarget.current.scrollTop = 0;
            }
            displaySuccessMessage();
            reset(defaultValues);
            addAnother = false;
        }
    };

    useEffect(() => {
        setValue('locationIds', selectedLocations);
    }, [selectedLocations]);

    const onSubmit = async (data: AddPractitionDto) => {
        const practitionerDto = { ...data, locationIds: selectedLocations } as CreatePractitionerDto;

        setIsSubmitting(true);
        const result = await httpService.createPractitioner(practitionerDto);
        setIsSubmitting(false);
        if (resultIsError(result)) {
            setServerError(result.message);
            return false;
        } else {
            mixpanel.track('Invited New User');
            if (addAnother) {
                return true;
            } else {
                refreshPractitioners();
                successToast('Email invite successfully sent to the provider.');
                handleCancel(null);
            }
            return false;
        }
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currentSelectedLocations = selectedLocations;
        if (event.target.checked) {
            setSelectedLocations([...currentSelectedLocations, Number(event.target.name)]);
        } else {
            const index = selectedLocations.indexOf(Number(event.target.name));
            if (index > -1) {
                currentSelectedLocations.splice(index, 1);
            }
            setSelectedLocations([...currentSelectedLocations]);
        }
    };

    const handleClearSelection = () => {
        setSelectedLocations([]);
    };

    const handleSelectAll = () => {
        if (!locations) return;
        const allLocations = locations.map((l) => {
            return l.id!;
        });
        setSelectedLocations([...allLocations]);
    };

    return (
        <>
            {showMessage && (
                <SuccessMessage className={showMessage ? 'success' : ''}>
                    <div>Provider Added</div>
                </SuccessMessage>
            )}
            <PractitionerForm onSubmit={handleSubmit(onSubmit)} data-cy="add-practitioner-form">
                <FormTextField name="firstName" label="First Name" control={control} errors={errors} required={true} />
                <FormTextField name="lastName" label="Last Name" control={control} errors={errors} required={true} />
                <FormTextField name="email" label="Email" control={control} errors={errors} required={true} />
                <FormFieldContainer>
                    <Controller
                        name="role"
                        control={control}
                        render={({ field }) => {
                            return (
                                <FormControl variant="outlined">
                                    <InputLabel shrink htmlFor="role">
                                        Role
                                    </InputLabel>
                                    {/* <KeyboardArrowDownRoundedIcon /> */}
                                    <PractitionerRoleSelect label="Role" {...field}>
                                        {Object.keys(ProviderRoles).map((key) => {
                                            return (
                                                <MenuItem key={key} value={key}>
                                                    <div>
                                                        <dt>{ProviderRoles[key]}</dt>
                                                        <dd>
                                                            {ProviderRoles[key] === ProviderRoles.PORTAL_PROVIDER_ADMIN
                                                                ? `Access and administrative controls of the Organization. Can invite others and manage patients.`
                                                                : `Ability to manage patients.`}
                                                        </dd>
                                                    </div>
                                                </MenuItem>
                                            );
                                        })}
                                    </PractitionerRoleSelect>

                                    <p> </p>
                                </FormControl>
                            );
                        }}
                    />
                </FormFieldContainer>
                {locations && (
                    <FormFieldContainer>
                        <Controller
                            name="locationIds"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <MultiSelectField
                                        label="locations"
                                        prefix="locations-"
                                        options={locations}
                                        selected={selectedLocations}
                                        {...field}
                                        handleRadioChange={handleRadioChange}
                                        handleClearSelection={handleClearSelection}
                                        handleSelectAll={handleSelectAll}
                                        error={errors.locationIds?.message}
                                    />
                                );
                            }}
                        />
                    </FormFieldContainer>
                )}

                {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ServerError>{errors.locationIds?.message || '\u00a0'}</ServerError>
                </div> */}

                <ServerError>{serverError || '\u00a0'}</ServerError>
                <SaveSaveAnotherCancelButtons
                    saveFunction={handleSubmit(onSubmit)}
                    saveAndAddAnotherFunction={handleSubmit(saveAndAddAnother)}
                    cancelFunction={handleCancel}
                    disabled={isSubmitting}
                />
            </PractitionerForm>
        </>
    );
};
