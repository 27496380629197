import React, { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, TextField, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { Paths } from '@/common/const';
import { useToast } from '@/common/hooks/useToast';
import { AuthForm, AuthPasswordControl } from '@/components/Auth/styles';
import { EyeClosedIcon } from '@/components/common/Icons/EyeClosedIcon';
import { EyeOpenIcon } from '@/components/common/Icons/EyeOpenIcon';

const schema = yup.object().shape({
    code: yup.string().required('Verification code is required.'),
    password: yup.string().required('Password is required.'),
});

type Data = {
    username: string;
    code: string;
    password: string;
};

interface State {
    password: string;
    showPassword: boolean;
}

type LocationState = { state: { username: string } };

export const NewPasswordForm = () => {
    const { successToast } = useToast();
    const { register, handleSubmit, formState, setError } = useForm({ resolver: yupResolver(schema) });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { state } = useLocation() as LocationState;
    const [values, setValues] = useState<State>({
        password: '',
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const forgotPassword = async (data: Data) => {
        setLoading(true);
        try {
            await Auth.forgotPasswordSubmit(state.username, data.code, data.password);
            navigate(Paths.userLogin, { replace: true });
            setLoading(false);
            successToast('Your password has been reset. Please login with your new password.');
        } catch (e) {
            setLoading(false);
            setError('password', {
                type: 'manual',
                message: `${e.message}`,
            });
            return;
        }
    };

    return (
        <>
            <AuthForm data-cy="login-form" onSubmit={handleSubmit(forgotPassword)} noValidate>
                <TextField
                    variant="outlined"
                    {...register('code')}
                    margin="normal"
                    required
                    fullWidth
                    id="code"
                    label="Verification Code"
                    name="code"
                    autoComplete="false"
                    error={!!formState.errors.code}
                    helperText={formState.errors.code && (formState.errors.code.message as string)}
                />
                <AuthPasswordControl variant="outlined" error={!!formState.errors.password} fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                    <OutlinedInput
                        {...register('password')}
                        required
                        id="password"
                        type={values.showPassword ? 'text' : 'password'}
                        name="password"
                        autoComplete="new-password"
                        label="New Password"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText id="password">
                        {formState.errors.password && (formState.errors.password.message as string)}
                    </FormHelperText>
                </AuthPasswordControl>
                <Box my={3}>
                    <LoadingButton loading={loading} variant="primary" fullWidth type="submit">
                        Continue
                    </LoadingButton>
                </Box>
            </AuthForm>
        </>
    );
};
