import { useContext } from 'react';
import { UserContext } from '@exerai/react-core';
import { Products, ProviderAndExerRoles } from '@/common/const';
import { ExerProviderUser } from '@/common/types';
import { getHighestRole, getStringEnumKeyByValue, onlyAvailableProductToUser } from '@/common/utils';

export const useExerUser = () => {
    const { user, dispatchUser } = useContext(UserContext);
    const exerProviderUser = user as ExerProviderUser;

    const isGaitUser = (): boolean => {
        const key = getStringEnumKeyByValue(Products, Products.GAIT);
        if (!exerProviderUser.products) {
            return false;
        }
        return exerProviderUser.products.includes(key);
    };

    const isHealthUser = (): boolean => {
        const key = getStringEnumKeyByValue(Products, Products.HEALTH);
        if (!exerProviderUser.products) {
            return false;
        }
        return exerProviderUser.products?.includes(key);
    };

    const isScanOnlyUser = (): boolean => {
        return onlyAvailableProductToUser(exerProviderUser.products) === Products.SCAN;
    };

    const getRole = (): string => {
        return ProviderAndExerRoles[getHighestRole(exerProviderUser)];
    };

    return {
        user: exerProviderUser,
        dispatchUser,
        isGaitUser,
        isHealthUser,
        isScanOnlyUser,
        getRole,
    };
};
