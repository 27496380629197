import React, { useEffect, useState } from 'react';
import { Tooltip, IconButton } from '@mui/material';
import { useRecoilState } from 'recoil';
import { RtmCycleStatus } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { mixpanel } from '@/common/mixpanel';
import { PromotionStatusEnum, RTMMonthCycle, RTMPatientRecord } from '@/common/types';
import { preventParentLinkClick } from '@/common/utils';
import { ActivityDataPatient } from '@/components/Health/PatientSessions/SessionsPDF/types';
import { RadioCheckBox } from '@/components/common/Icons/RadioCheckBox';
import { RTMState as RTMAtomState, RTMLoadingStatus } from '@/recoil/atoms/rtmAtom';
import { resultIsError } from '@/services/HttpService';

export const MarkAsBilledAction = ({
    cycle,
    patientRecord,
}: {
    cycle: RTMMonthCycle;
    patientRecord: RTMPatientRecord;
}) => {
    const { id, status } = cycle;
    const { httpService } = useHttpContext();
    const [RTMState, setRTMState] = useRecoilState(RTMAtomState);
    const [currentPatient, setCurrentPatient] = useState<ActivityDataPatient>();
    const { errorToast, successToast } = useToast();

    const UpdateRTMCycle = async (e) => {
        preventParentLinkClick(e);
        let params;

        if (status === RtmCycleStatus.READY_TO_BILL) {
            params = { id, promotionStatus: PromotionStatusEnum.ADVANCE };
            mixpanel.track(`User marked a patient's status as billed`, { status: status });

            if (currentPatient) {
                successToast(
                    `Successfully marked RTM Cycle for ${currentPatient.firstName} ${currentPatient.lastName} as billed.`,
                );
            }
        } else if (status === RtmCycleStatus.BILLED) {
            params = { id, promotionStatus: PromotionStatusEnum.REVERT };
        }

        const res = await httpService.updateRTMCycle(params);

        if (resultIsError(res)) {
            errorToast(`Something went wrong, please refresh and try again`);
        } else {
            setRTMState({ ...RTMState, loadCycles: RTMLoadingStatus.IDLE });
        }
    };

    useEffect(() => {
        setCurrentPatient(patientRecord);
    }, [cycle]);

    return (
        <Tooltip title={`${status === RtmCycleStatus.READY_TO_BILL ? 'Mark' : 'Unmark'} as Billed`}>
            <IconButton onClick={UpdateRTMCycle} style={{ fontSize: 20 }}>
                <RadioCheckBox checked={status === RtmCycleStatus.READY_TO_BILL} />
            </IconButton>
        </Tooltip>
    );
};
