import React from 'react';
import { Grid } from '@mui/material';
import { CardContent, CardActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Paths } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { isAdmin } from '@/common/utils';
import { ComputerGraphIcon } from '@/components/common/Icons/ComputerGraphIcon';
import { IndustrialBuildingIcon } from '@/components/common/Icons/IndustrialBuildingIcon';
import { PatientsIconOutline } from '@/components/common/Icons/PatientsIconOutline';
import { Button } from '@/components/common/button/button';
import { WeightIcon } from '../common/Icons/WeightIcon';
import { EmptyDashboardCard, EmptyDashboardCardHeader, EmptyDashboardAvatar } from './styles';

export const EmptyDashboardContainer = () => {
    const navigate = useNavigate();
    const { user, isHealthUser } = useExerUser();

    const handleAddPatientsClick = () => {
        navigate(`${Paths.patients}?add=true`);
    };

    const handleBuildTemplateClick = () => {
        navigate(`${Paths.programsHEPTemplates}?add=true`);
    };

    const handleViewExercisesClick = () => {
        navigate(Paths.programsExerciseLibrary);
    };

    const handlePractitionersClick = () => {
        navigate(Paths.organizationPractitioners);
    };

    return (
        <Grid container spacing={3}>
            <Grid item sm={6}>
                <EmptyDashboardCard variant="outlined" elevation={4}>
                    <EmptyDashboardCardHeader
                        avatar={
                            <EmptyDashboardAvatar>
                                <PatientsIconOutline />
                            </EmptyDashboardAvatar>
                        }
                        title="Add patients to Exer Health"
                    />
                    <CardContent>Invite patients to perform exercise programs at home</CardContent>
                    <CardActions>
                        <Button onClick={handleAddPatientsClick} variant="primary" size="small">
                            Add First Patient
                        </Button>
                    </CardActions>
                </EmptyDashboardCard>
            </Grid>
            {isHealthUser() && (
                <Grid item sm={6}>
                    <EmptyDashboardCard variant="outlined" elevation={4}>
                        <EmptyDashboardCardHeader
                            avatar={
                                <EmptyDashboardAvatar>
                                    <ComputerGraphIcon />
                                </EmptyDashboardAvatar>
                            }
                            title="Build an at-home exercise program"
                        />
                        <CardContent>Create program templates you can use in the future.</CardContent>
                        <CardActions>
                            <Button onClick={handleBuildTemplateClick} variant="primary" size="small">
                                Create Program
                            </Button>
                        </CardActions>
                    </EmptyDashboardCard>
                </Grid>
            )}
            {isAdmin(user) && (
                <Grid item sm={6}>
                    <EmptyDashboardCard variant="outlined" elevation={4}>
                        <EmptyDashboardCardHeader
                            avatar={
                                <EmptyDashboardAvatar>
                                    <IndustrialBuildingIcon />
                                </EmptyDashboardAvatar>
                            }
                            title="Invite your team"
                        />
                        <CardContent>Add other providers from your organization to the portal.</CardContent>
                        <CardActions>
                            <Button onClick={handlePractitionersClick} variant="tertiary" size="small">
                                Add Providers
                            </Button>
                        </CardActions>
                    </EmptyDashboardCard>
                </Grid>
            )}
            {isHealthUser() && (
                <Grid item sm={6}>
                    <EmptyDashboardCard variant="outlined" elevation={4}>
                        <EmptyDashboardCardHeader
                            avatar={
                                <EmptyDashboardAvatar>
                                    <WeightIcon />
                                </EmptyDashboardAvatar>
                            }
                            title="Browse the Motion Library"
                        />
                        <CardContent>Extensive library of exercises you can use to build programs</CardContent>
                        <CardActions>
                            <Button onClick={handleViewExercisesClick} variant="tertiary" size="small">
                                Start browsing
                            </Button>
                        </CardActions>
                    </EmptyDashboardCard>
                </Grid>
            )}
        </Grid>
    );
};
