import React from 'react';
import { Tooltip } from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useToast } from '@/common/hooks/useToast';
import { CopyIcon } from '@/components/common/Icons/CopyIcon';
import { CopyButton, CopyTextWrapper } from '@/components/common/Table/style';

interface ScanUUIDDisplayProps {
    uuid: string;
}
export const ScanUUIDDisplay = (props: ScanUUIDDisplayProps) => {
    const { uuid } = props;
    const { successToast } = useToast();
    const display = `${uuid.slice(0, 4)}...${uuid.slice(uuid.length - 5, uuid.length - 1)}`;

    const handleOpen = () => {
        successToast('Copied UUID.');
    };

    return (
        <>
            <Tooltip title={uuid} style={{ cursor: 'pointer' }}>
                <span>{display}</span>
            </Tooltip>
            <CopyButton size="small" onClick={handleOpen}>
                <CopyToClipboard text={uuid}>
                    <CopyTextWrapper>
                        <CopyIcon sx={{ fill: 'none' }} />
                    </CopyTextWrapper>
                </CopyToClipboard>
            </CopyButton>
        </>
    );
};
