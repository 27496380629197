import { Dispatch, MouseEvent, TouchEvent } from 'react';
import { actions } from '@exerai/react-core';
import { isPast, isToday, isFuture, parseISO } from 'date-fns';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import {
    ASSESSMENT_FILTER,
    SUBJECT_POSITION_FILTER,
    CAMERA_ORIENTATION_FILTER,
    EQUIPMENT_FILTER,
    FAVORITE_FILTER,
    METRICS_FILTER,
    BODY_TARGET_FILTER,
    EXERCISE_TYPE_FILTER,
} from '@/components/Health/HEPBuilder/ExerciseFilters/ExerciseFilters';
import { Result } from '@/services/HttpService';
import { IHttpService } from '@/services/IHttpService';
import {
    BodyTarget,
    SubjectPosition,
    CameraOrientation,
    Equipment,
    ExerciseType,
    IsFavoriteOptions,
    Paths,
    Products,
    SideOfBody,
    IsAssessmentOptions,
    MetricCode,
    RtmCycleStatus,
    ProviderRoles,
    DischargeOrPocEnum,
    ProviderOnlyProducts,
} from './const';
import {
    BlockGroupedSet,
    BlockSetMap,
    CSVGroupedSet,
    ExercisesDict,
    ExerciseSetInfo,
    ExerProviderUser,
    Program,
    PatientRecord,
    RawSession,
    ROLES,
    Session,
    SessionsByDateDict,
    UserProfile,
    GaitSessionsByDateDict,
    GaitSession,
    ChunkDataProps,
    Practitioner,
    PractitionerOption,
    CreateMarksInterface,
    isROMExerciseMetricSummary,
    PROLabels,
    Choice,
    BlockGroupedSetBySide,
    MinMaxLabel,
    Location,
    PlanOfCare,
    Maybe,
    ScanModule,
    Assessment,
    SortableScanModule,
} from './types';

export const refreshUserProfile = async (
    httpService: IHttpService,
    dispatchUser,
    userProfile?: UserProfile,
): Promise<{ success: boolean; errorMsg?: string; logout?: boolean }> => {
    let currentUser: UserProfile;
    if (!userProfile) {
        let currentUserResult: Result<UserProfile> = await httpService.getCurrentUser();
        let retries = 0;

        while (!isUserProfile(currentUserResult)) {
            if (currentUserResult.status !== 0) {
                return {
                    success: false,
                    errorMsg: currentUserResult.message,
                    logout: currentUserResult.status === 404, // if user has no practitioner record in environment
                };
            }
            if (retries === 2) {
                return {
                    success: false,
                    errorMsg: `We're unable to connect to the Exer service. This may be a restriction on the network. You can try to reload the page, or switching to a different network.`,
                    logout: false,
                };
            }
            retries++;
            await sleep(200);
            currentUserResult = await httpService.getCurrentUser();
        }
        currentUser = currentUserResult;
    } else {
        currentUser = userProfile;
    }

    if (currentUser) {
        dispatchUser({
            type: actions.UPDATE,
            payload: {
                firstName: currentUser.firstName,
                lastName: currentUser.lastName,
                organizationId: currentUser.organization?.id,
                organizationName: currentUser.organization?.name,
                organizationTimezone: currentUser.organization?.timezone,
                organizationLogoUrl: currentUser.organization?.logoUrl,
                products: currentUser.organization?.products,
                locations: currentUser.locations,
            },
        });
        return {
            success: true,
        };
    }
    return { success: false };
};

export const sleep = async (ms: number): Promise<number> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getStringEnumKeyByValue = <T extends Record<string | number | symbol, unknown>>(
    enumerable: T,
    value: T[keyof T],
): keyof T => {
    const keys = Object.keys(enumerable).filter((val) => enumerable[val] === value);
    return keys[0];
};

export const getOptionEnumById = (id: string) => {
    let typeEnum;
    switch (id) {
        case EXERCISE_TYPE_FILTER:
            typeEnum = ExerciseType;
            break;
        case METRICS_FILTER:
            typeEnum = MetricCode;
            break;
        case BODY_TARGET_FILTER:
            typeEnum = BodyTarget;
            break;
        case EQUIPMENT_FILTER:
            typeEnum = Equipment;
            break;
        case FAVORITE_FILTER:
            typeEnum = IsFavoriteOptions;
            break;
        case ASSESSMENT_FILTER:
            typeEnum = IsAssessmentOptions;
            break;
        case SUBJECT_POSITION_FILTER:
            typeEnum = SubjectPosition;
            break;
        case CAMERA_ORIENTATION_FILTER:
            typeEnum = CameraOrientation;
            break;
    }
    return typeEnum;
};

export const productUserCanAdd = (user: ExerProviderUser, product: Products): Products | null => {
    const key = getStringEnumKeyByValue(Products, product);
    if (!key || !user.products) {
        return null;
    }
    return user.products.includes(key) ? product : null;
};

export const productCanBeAddedToPatientWithoutAdditionalInfo = (
    patientRecord: PatientRecord,
    product: Products,
): boolean => {
    switch (product) {
        case Products.GAIT:
            return !!patientRecord.patient?.firstName && !!patientRecord.patient?.lastName;
        case Products.HEALTH:
            return (
                !!patientRecord.patient?.firstName &&
                !!patientRecord.patient?.lastName &&
                !!patientRecord.patient?.email &&
                !!patientRecord.productData?.health?.injuredBodyPart &&
                !!patientRecord.productData?.health?.injuredSideOfBody
            );
        default:
            return true;
    }
};

export const canNotSelectMultiple = (user: ExerProviderUser): boolean => {
    return user.products ? !(productsIncludesHealth(user.products) && productsIncludesGait(user.products)) : true;
};

export const onlyAvailableProductToAddUsers = (products: Maybe<string[]>): Maybe<Products> => {
    const eligibleProducts = products?.filter((p) => {
        return ![
            getStringEnumKeyByValue(Products, Products.SCAN),
            getStringEnumKeyByValue(Products, Products.PRO),
        ].includes(p as any);
    });
    return onlyAvailableProductToUser(eligibleProducts);
};

export const onlyAvailableProductToUser = (products: Maybe<string[]>): Maybe<Products> => {
    if (!products || products.length >= 2) {
        return undefined;
    }
    const onlyProduct = products[0] && Products[products[0]];
    return onlyProduct as Products;
};

export const groupByBlock = (sets: ExerciseSetInfo[], program: Program): Map<string | number, BlockGroupedSet> => {
    /* TODO Is this being used? */
    const blocks = new Map<string | number, BlockGroupedSet>();
    const setsToBlock = sets ? [...sets] : [];
    program.blocks.forEach((block) => {
        const fallbackId = Date.now() + Math.random();
        [...Array(block.numSets)].forEach(() => {
            const id = block.id || fallbackId;
            blocks.set(id, blocks.get(id) || { id, exercise: { name: '', sets: [] } });
            const hepBlock = blocks.get(id);
            const set = setsToBlock.shift();
            if (set && hepBlock) {
                hepBlock.exercise.name = set.exercise?.name;
                hepBlock.exercise.sets.push(set);
            }
            if (hepBlock) {
                blocks.set(id, hepBlock);
            }
        });
    });
    return blocks;
};

export const groupByExerciseName = (sets: ExerciseSetInfo[]): Map<number, BlockGroupedSet> => {
    const exercises = new Map<number, BlockGroupedSet>();
    sets.forEach((set) => {
        if (set.exercise) {
            const id = set.exercise.id;
            const name = set.exercise.name;
            exercises.set(id, exercises.get(id) || { id, exercise: { name, sets: [] } });
            const block = exercises.get(id);
            if (block) {
                block.exercise.sets.push(set);
                exercises.set(id, block);
            }
        }
    });
    return exercises;
};

export const groupByExerciseAndSide = (sets: ExerciseSetInfo[]): Map<string, BlockGroupedSetBySide> => {
    const exercises = new Map<string, BlockGroupedSetBySide>();
    sets.forEach((set) => {
        if (set.exercise) {
            const id = set.setInfo.sideOfBody ? `${set.exercise.id}-${set.setInfo.sideOfBody}` : `${set.exercise.id}`;
            const name = set.setInfo.sideOfBody
                ? `${set.exercise.name} - ${SideOfBody[set.setInfo.sideOfBody]}`
                : set.exercise.name;
            exercises.set(id, exercises.get(id) || { id, exercise: { name, sets: [] } });
            const block = exercises.get(id);
            if (block) {
                block.exercise.sets.push(set);
                exercises.set(id, block);
            }
        }
    });
    return exercises;
};

export const groupByExerciseNameWithMinMax = (
    sets: ExerciseSetInfo[],
    exercisesDict: ExercisesDict,
): Map<number, CSVGroupedSet> => {
    const exercises = new Map<number, CSVGroupedSet>();
    sets.forEach((set) => {
        if (set.exercise) {
            if (set.setInfo.maxROM !== undefined) {
                ['min', 'max'].forEach((m) => {
                    const id = set.exercise.id + (m === 'min' ? 11111111 : 99999999);
                    const name = `"${
                        exercisesDict?.[set.exercise.id]?.name + (m === 'min' ? ' - START RANGE' : ' - END RANGE')
                    }"`;
                    exercises.set(id, exercises.get(id) || { name, sets: [], isMin: m === 'min', isMax: m === 'max' });
                    const exercise = exercises.get(id);
                    if (exercise) {
                        exercise.sets.push(set);
                        exercises.set(id, exercise);
                    }
                });
            } else {
                const id = set.exercise.id;
                const name = `"${exercisesDict?.[set.exercise.id]?.name}"`;
                exercises.set(id, exercises.get(id) || { name, sets: [] });
                const exercise = exercises.get(id);
                if (exercise) {
                    exercise.sets.push(set);
                    exercises.set(id, exercise);
                }
            }
        }
    });
    return exercises;
};

export const sortBySessionDate = (sessions: Session[]): Session[] => {
    const sortedSessions = [...sessions];
    return sortedSessions.sort((a, b) => {
        return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
    });
};

export const groupBySessionDate = (sessions: Session[]): SessionsByDateDict => {
    const orderedSessionsByDateDict = {};
    const grouped = sortBySessionDate(sessions).reduce((sessions, session) => {
        const createdDate = DateTime.fromISO(session.createdDate).toLocaleString(DateTime.DATE_FULL);
        sessions[createdDate] = sessions[createdDate] || [];
        sessions[createdDate].push(session);
        return sessions;
    }, {});
    const orderedDates = Object.keys(grouped).sort((a, b) => {
        return new Date(b).getTime() - new Date(a).getTime();
    });
    orderedDates.forEach((date) => {
        orderedSessionsByDateDict[date] = grouped[date];
    });
    return orderedSessionsByDateDict;
};

export const sortByGaitSessionDate = (sessions: GaitSession[]): GaitSession[] => {
    const sortedSessions = [...sessions];
    return sortedSessions.sort((a, b) => {
        return new Date(b.session.meta.capturedDate).getTime() - new Date(a.session.meta.capturedDate).getTime();
    });
};

export const groupByGaitSessionDate = (sessions: GaitSession[]): GaitSessionsByDateDict => {
    return sortByGaitSessionDate(sessions).reduce((sessions, session) => {
        const sessionDate = new Date(session.session.meta.capturedDate).setHours(0, 0, 0, 0);

        sessions[DateTime.fromMillis(sessionDate).toISO()] = sessions[DateTime.fromMillis(sessionDate).toISO()] || [];
        sessions[DateTime.fromMillis(sessionDate).toISO()].push(session);
        return sessions;
    }, {});
};

export const isTodayString = (dateString: string) => {
    return dateString === DateTime.now().toLocaleString(DateTime.DATE_FULL) ? 'Today • ' : '';
};

export const calculatePercentComplete = (blocks: BlockSetMap): number => {
    let total = 0;
    let totalComplete = 0;
    for (const [_, block] of blocks) {
        block.exercise.sets.forEach((set) => {
            total++;
            if (set.setInfo.maxROM !== undefined) {
                totalComplete = set.setInfo.skipped
                    ? totalComplete
                    : totalComplete + (set.setInfo.maxROM === 0 && set.setInfo.minROM === 0 ? 0 : 1);
            } else if (set.setInfo.repsGoal && set.setInfo.repsGoal > 0) {
                totalComplete = set.setInfo.skipped
                    ? totalComplete
                    : totalComplete + (set.setInfo.repsComplete || 0) / set.setInfo.repsGoal;
            } else if (set.setInfo.timeGoal && set.setInfo.timeGoal > 0) {
                totalComplete = set.setInfo.skipped
                    ? totalComplete
                    : totalComplete + (set.setInfo.timeComplete || 0) / set.setInfo.timeGoal;
            }
        });
    }
    const percentComplete = Math.min(100, Math.round((totalComplete / total) * 100));
    return isNaN(percentComplete) ? 0 : percentComplete;
};

export const getProductsWeight = (products: string[]) => {
    return !products || products.length < 1
        ? 0
        : products.length === 2
        ? 3
        : products[0] === getStringEnumKeyByValue(Products, Products.HEALTH)
        ? 1
        : 2;
};

export const productsIncludesHealth = (products: string[]): boolean => {
    const key = getStringEnumKeyByValue(Products, Products.HEALTH);
    return products && products.includes(key);
};

export const productsIncludesGait = (products: string[]): boolean => {
    const key = getStringEnumKeyByValue(Products, Products.GAIT);
    return products && products.includes(key);
};

export const productsIncludesPRO = (products: string[]): boolean => {
    const key = getStringEnumKeyByValue(Products, Products.PRO);
    return products && products.includes(key);
};

export const saturateSessionWithExerciseNames = (rawSessions: RawSession[], exercisesDict: ExercisesDict) => {
    return rawSessions.map((rawSession) => {
        const session = { ...rawSession } as Session;
        session.session.sets = rawSession.session.sets.map((rawSet) => {
            const set = { ...rawSet } as ExerciseSetInfo;
            const exercise = exercisesDict?.[rawSet.exercise.id];
            set.exercise.name = exercise?.name || rawSet.exercise.name || '';
            if (isROMExerciseMetricSummary(exercise)) {
                set.exercise.minMaxLabel = MinMaxLabel[exercise.romProperties.minMaxLabel];
                set.exercise.min = { label: exercise.romProperties.min.label || 'Start Range' };
                set.exercise.max = { label: exercise.romProperties.max.label || 'End Range' };
            }
            return set;
        });
        return session;
    });
};

/* https://dev.to/andrewchmr/react-hh-mm-ss-time-input-cfl */

export const getSecondsFromHHMMSS = (value) => {
    const [str1, str2, str3] = value.split(':');

    const val1 = Number(str1);
    const val2 = Number(str2);
    const val3 = Number(str3);

    if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
        // seconds
        return val1;
    }

    if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
        // minutes * 60 + seconds
        return val1 * 60 + val2;
    }

    if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
        // hours * 60 * 60 + minutes * 60 + seconds
        return val1 * 60 * 60 + val2 * 60 + val3;
    }

    return 0;
};

export const toHHMMSS = (secs) => {
    const secNum = parseInt(secs.toString().replace(/([^0-9:])+/g, ''), 10);
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [hours, minutes, seconds]
        .map((val) => (val < 10 ? `0${val}` : val))
        .filter((val, index) => val !== '00' || index > 0)
        .join(':')
        .replace(/^0/, '');
};

export const preventParentLinkClick = (event: MouseEvent | TouchEvent) => {
    event.preventDefault();
    event.stopPropagation();
};

export const CreateMarks = (props: CreateMarksInterface) => {
    const { total, steps, additionalLabel = '' } = props;
    const marks = Array.from({ length: total }, (_, i) => {
        const firstLast = i === 0 || i === total - 1;
        const nth = i % steps === steps - 1;
        return { label: firstLast || nth ? `${i + 1}${additionalLabel}` : '', value: i + 1 };
    });

    return marks;
};

export const CreateIntegerArray = (total) => {
    const marks = Array.from({ length: total }, (v, i) => {
        return { label: i + 1, value: i + 1 };
    });

    return marks;
};

export const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
};

export const userHasRole = (user: ExerProviderUser, role: ROLES): boolean => {
    return user.groups.includes(role);
};

export const isPractitioner = (user: ExerProviderUser): boolean => {
    if (user.groups?.includes('PORTAL_PRACTITIONER')) {
        return true;
    }
    return false;
};

export const isAdmin = (user: ExerProviderUser): boolean => {
    if (user.groups?.includes('PORTAL_PROVIDER_ADMIN')) {
        return true;
    }
    return false;
};

export const isExerAdmin = (user: ExerProviderUser): boolean => {
    if (user.groups?.includes('EXER_ADMIN')) {
        return true;
    }
    return false;
};

export const getHighestRole = (user: ExerProviderUser): ROLES => {
    if (user.groups?.includes('EXER_ADMIN')) {
        return 'EXER_ADMIN';
    }
    if (user.groups?.includes('PORTAL_PROVIDER_ADMIN')) {
        return 'PORTAL_PROVIDER_ADMIN';
    }
    if (user.groups?.includes('PORTAL_PRACTITIONER')) {
        return 'PORTAL_PRACTITIONER';
    }
    return 'UNKNOWN';
};

export const precisionRound = (number: number, precision: number) => {
    if (precision < 0) {
        const factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
    } else return +(Math.round(Number(number + 'e+' + precision)) + 'e-' + precision);
};

export const stripNonDigitCharacters = (string: string): string => {
    return string.replace(/\D/g, '');
};

export const phoneNumberTest = (val: string): boolean => {
    return !val || stripNonDigitCharacters(val).length === 10;
};

export const formatUSPhoneNumber = (val: string): string => {
    return '+1' + stripNonDigitCharacters(val);
};

export const formatPhoneNumber = (phoneNumberString) => {
    const match = stripNonDigitCharacters(phoneNumberString).match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
};

export const a11yProps = (tab: number | string) => {
    let ariaName: string | number;
    if (typeof tab === 'string') {
        ariaName = tab.replace(/\s/g, '-').toLowerCase();
    } else {
        ariaName = tab;
    }
    return {
        id: `${ariaName}-tab`,
        'aria-controls': `${ariaName}-tabpanel`,
    };
};

export const characterCountWithLineBreaks = (str: string) => {
    try {
        return str.replace(/(\r\n|\n|\r)/g, '--').length;
    } catch (e) {
        return 0;
    }
};

export const getStartAndEndOfDateInMillis = (date: string | Date) => {
    const dateString = date instanceof Date ? date : new Date(date);
    const startDate = DateTime.fromJSDate(dateString).startOf('day').toMillis();
    const endDate = DateTime.fromJSDate(dateString).endOf('day').toMillis();
    return { startDate, endDate };
};

export const gaitSortOrder = (name) => {
    switch (name) {
        case 'rightFlexion':
            return 0;
        case 'leftFlexion':
            return 1;
        case 'rightExtension':
            return 2;
        case 'leftExtension':
            return 3;
        case 'flexionDifference':
            return 0;
        case 'extensionDifference':
            return 1;
        default:
            return 0;
    }
};

export const chunkData = <T>({ data, chunkSize }: ChunkDataProps): Array<Array<T>> => {
    const chunk = (data, chunkSize) =>
        Array.from({ length: Math.ceil(data.length / chunkSize) }, (v, i) =>
            data.slice(i * chunkSize, i * chunkSize + chunkSize),
        );
    return chunk(data, chunkSize);
};

export const reorderBodyTargetSet = (bodyTargets: Set<string>): Set<string> => {
    const orderedBodyTargets = new Set<string>();
    const candidateBodyTargets = Object.keys(BodyTarget).filter((bodyTarget) => {
        return bodyTargets.has(bodyTarget);
    });
    candidateBodyTargets.forEach((bodyTarget) => {
        orderedBodyTargets.add(bodyTarget);
    });
    return orderedBodyTargets;
};

export const getPractitionerOptions = (
    allPractitioners: Practitioner[],
    setSelected: Dispatch<PractitionerOption>,
    selected?: PractitionerOption,
): PractitionerOption[] => {
    const uniquePractitioners: PractitionerOption[] = [];
    allPractitioners.forEach((practitioner) => {
        const found = uniquePractitioners.findIndex((p) => {
            return p.name === `${practitioner.firstName} ${practitioner.lastName}`;
        });
        if (found === -1) {
            uniquePractitioners.push({
                id: practitioner.id,
                name: `${practitioner.firstName} ${practitioner.lastName}`,
            });
        } else {
            const practitionerWithId = {
                id: practitioner.id,
                name: `${practitioner.firstName} ${practitioner.lastName} ${practitioner.id}`,
            };
            uniquePractitioners.push(practitionerWithId);
            if (selected?.id === practitioner.id) {
                setSelected(practitionerWithId);
            }
            const dupe = uniquePractitioners[found];
            uniquePractitioners[found] = { ...dupe, name: dupe.name + ' ' + dupe.id };
        }
    });
    return uniquePractitioners;
};

export const getHEPUrl = (id: number): string => {
    if (!id) {
        return '';
    }
    return window.location.protocol + '//' + window.location.host + Paths.programsHEPTemplates + id;
};

export const isGaitPatient = (patientRecord: PatientRecord): boolean => {
    const key = getStringEnumKeyByValue(Products, Products.GAIT);
    return patientRecord.products?.includes(key!);
};

export const isHealthPatient = (patientRecord: PatientRecord): boolean => {
    const key = getStringEnumKeyByValue(Products, Products.HEALTH);
    return patientRecord.products?.includes(key);
};

export const isHealthOrPROPatient = (patientRecord: PatientRecord): boolean => {
    const key = getStringEnumKeyByValue(Products, Products.HEALTH);
    const proKey = getStringEnumKeyByValue(Products, Products.PRO);
    return patientRecord.products?.includes(key) || patientRecord.products?.includes(proKey!);
};

export const isHealthOrGaitPatient = (patientRecord: PatientRecord): boolean => {
    const key = getStringEnumKeyByValue(Products, Products.HEALTH);
    const gaitKey = getStringEnumKeyByValue(Products, Products.GAIT);
    return patientRecord.products?.includes(key) || patientRecord.products?.includes(gaitKey!);
};

export const isPROOnlyPatient = (patientRecord: PatientRecord): boolean => {
    if (patientRecord.products?.length !== 1) {
        return false;
    }
    const proKey = getStringEnumKeyByValue(Products, Products.PRO);
    return patientRecord.products?.includes(proKey);
};

let patientProductkeys: string[];
export const getPatientProductKeys = (): string[] => {
    if (patientProductkeys) {
        return patientProductkeys;
    } else {
        patientProductkeys = Object.keys(Products).filter((key) => !ProviderOnlyProducts.includes(Products[key]));
        return patientProductkeys;
    }
};

export const handleEnterPress = (e: React.KeyboardEvent, callback: () => void) => {
    if (e.key === 'Enter') {
        e.preventDefault();
        callback();
    }
};

export const getNumericalPROChartData = (patientId: number): PROLabels => {
    const levelOfStrengthPRO = {
        proQuestionText: 'How strong do you feel today?',
        proLabel: 'Strength Level',
    };
    const painlevelPRO = {
        proQuestionText: 'What is your level of pain today?',
        proLabel: 'Pain Level',
    };
    return process.env.STRENGTH_PATIENTS && JSON.parse(process.env.STRENGTH_PATIENTS)?.includes(patientId)
        ? levelOfStrengthPRO
        : painlevelPRO;
};

export const getSideOfBodyChoices = (): Choice[] => {
    const choices = [
        { value: getStringEnumKeyByValue(SideOfBody, SideOfBody.LEFT), label: SideOfBody.LEFT },
        {
            value: getStringEnumKeyByValue(SideOfBody, SideOfBody.RIGHT),
            label: SideOfBody.RIGHT,
        },
        {
            value: SideOfBody.N_A,
            label: SideOfBody.N_A,
        },
    ];
    return choices;
};

export const getJSDateFromMY = (m: number, y: number): Date => {
    // Date expects 0-based month int, we store calendar int
    return new Date(y, m - 1);
};

export const isBillable = (status: `${RtmCycleStatus}`): boolean => {
    return [RtmCycleStatus.BILLED.valueOf(), RtmCycleStatus.READY_TO_BILL.valueOf()].includes(status);
};

export const getLocation = (locations: Location[] | undefined, selectedId: string | number) => {
    if (!locations) return null;
    const locationId = typeof selectedId === 'number' ? selectedId : Number(selectedId);

    const location = locations.find((i) => i.id === locationId);

    return location ?? null;
};

export const filterProvidersByLocationId = (locationId: number, practitioners?: Practitioner[]) => {
    if (!practitioners) return;

    return practitioners.reduce((acc, practitioner) => {
        if (
            practitioner.locations.some((obj) => obj.id === locationId) ||
            (practitioner.role && ProviderRoles[practitioner.role] === ProviderRoles.PORTAL_PROVIDER_ADMIN)
        ) {
            return [...acc, practitioner];
        } else {
            return acc;
        }
    }, []);
};

export const patientNameFromRecord = (patientRecord: PatientRecord) => {
    return patientRecord.patient?.lastName
        ? `${patientRecord.patient.lastName}${
              patientRecord.patient?.firstName ? `, ${patientRecord.patient.firstName}` : ' (Missing First Name)'
          }`
        : patientRecord.patient?.firstName
        ? patientRecord.patient.firstName + ' (Missing Last Name)'
        : '(Missing Name)';
};

export const dischargeOrPoc = (date: Date | null) => {
    if (!date) return DischargeOrPocEnum.DISCHARGE;

    if (isPast(date) && !isToday(date)) {
        return DischargeOrPocEnum.DISCHARGE;
    }

    if (isToday(date) || isFuture(date)) {
        return DischargeOrPocEnum.POC;
    }
};

export const getEndDate = (dateList: PlanOfCare[]) => {
    if (dateList.length > 0 && dateList[0].end) {
        return parseISO(dateList[0].end) ?? null;
    } else {
        return null;
    }
};

export const checkForString = <T>(data: T | T[], searchString: string): boolean => {
    if (Array.isArray(data)) {
        return data.some((item) => checkForString(item, searchString));
    } else if (typeof data === 'object' && data !== null) {
        return Object.values(data).some((value) => checkForString(value, searchString));
    } else if (typeof data === 'string') {
        return data === searchString;
    }
    return false;
};

export const isUserProfile = (obj: any): obj is UserProfile => {
    return obj && obj.userId;
};

export const saturateModulesWithAssessments = (
    modules: ScanModule[],
    assessments: Assessment[],
): SortableScanModule[] => {
    const assessmentDict = assessments.reduce((acc, assessment) => {
        acc[assessment.id] = assessment;
        return acc;
    }, {} as { [key: string]: Assessment });

    return modules.map((module) => ({
        ...module,
        id: uuidv4(),
        assessments: module.assessments.map((moduleAssessment) => {
            const assessmentDetails = assessmentDict[moduleAssessment.id] || {};

            return {
                ...moduleAssessment,
                ...assessmentDetails,
                name: moduleAssessment.nameOverride || assessmentDetails.name,
                thumbnailUrl: assessmentDetails.thumbnailUrl,
            };
        }),
    }));
};

export const convertModulesToDTO = (modules: ScanModule[]): ScanModule[] => {
    return modules.map((module) => {
        return convertModuleToDTO(module);
    });
};

export const convertModuleToDTO = (module: ScanModule): ScanModule => ({
    name: module.name,
    subtitle: module.subtitle,
    imageName: module.imageName,
    imageUrl: module.imageUrl,
    isLocked: module.isLocked,
    assessments: module.assessments.map((assessment) => ({
        id: assessment.id,
        nameOverride: assessment.nameOverride,
    })),
});
