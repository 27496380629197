import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Products } from '@/common/const';
import { DialogTitleContainer } from '@/components/Demo/Paths/styles';
import { Button } from '@/components/common/button/button';

interface SessionReplayActionProps {
    sessionUUID: string;
    product: Products;
    children: JSX.Element | JSX.Element[];
}

export const SessionReplayAction = (props: SessionReplayActionProps) => {
    const { sessionUUID, product, children } = props;
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClick = () => {
        setDialogOpen(true);
    };

    const handleCloseModal = () => {
        setDialogOpen(false);
    };

    return (
        <>
            <Button onClick={handleClick} size="small">
                Replay
            </Button>
            {dialogOpen && (
                <Dialog open={true} keepMounted onClose={handleCloseModal} fullWidth maxWidth={'lg'}>
                    <DialogTitleContainer>
                        <DialogTitle>
                            {product} Pose Log {sessionUUID}
                        </DialogTitle>
                        <IconButton onClick={handleCloseModal}>X</IconButton>
                    </DialogTitleContainer>
                    <DialogContent>{children}</DialogContent>
                </Dialog>
            )}
        </>
    );
};
