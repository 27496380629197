import React, { useState, useEffect } from 'react';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { PatientRecord } from '@/common/types';
import { HEPProvider } from '@/components/context/HEPContext';
import { resultIsError } from '@/services/HttpService';
import { PortalEvent } from '@/services/events/const';
import eventService from '@/services/events/eventService';
import { Patient } from './Patient';
import { PatientProvider } from './context/PatientContext';

interface Props {
    patientRecordId?: number;
}
export const PatientContainer = (props: Props) => {
    const { patientRecordId } = props;
    const [patientRecord, setPatientRecord] = useState<PatientRecord>();
    const { httpService } = useHttpContext();
    const { errorToast } = useToast();

    const getSetPatient = async () => {
        if (patientRecordId) {
            const res = await httpService.getPatientRecord(patientRecordId);
            if (resultIsError(res)) {
                errorToast('Could not get patient details.');
                return;
            }
            setPatientRecord(res);
        } else {
            errorToast('Could not get patient details.');
        }
    };

    useEffect(() => {
        (async () => {
            await getSetPatient();
        })();
        eventService.on(PortalEvent.UPDATE_PATIENT, async (data) => {
            await getSetPatient();
        });
        return () => {
            eventService.remove(PortalEvent.UPDATE_PATIENT);
        };
    }, []);

    return !!patientRecord ? (
        <PatientProvider patientRecord={patientRecord}>
            <HEPProvider patientRecord={patientRecord}>
                <Patient />
            </HEPProvider>
        </PatientProvider>
    ) : null;
};
