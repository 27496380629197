import React, { useState } from 'react';
import { assignUserProps } from '@exerai/react-core';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, TextField, InputLabel, OutlinedInput, FormHelperText, Checkbox } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { Auth } from 'aws-amplify';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Paths } from '@/common/const';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { useToast } from '@/common/hooks/useToast';
import { UserProfile } from '@/common/types';
import { refreshUserProfile } from '@/common/utils';
import { AuthForm, AuthPasswordControl } from '@/components/Auth/styles';
import { FormControlLabel } from '@/components/common/DateRangeActions/styles';
import { EyeClosedIcon } from '@/components/common/Icons/EyeClosedIcon';
import { EyeOpenIcon } from '@/components/common/Icons/EyeOpenIcon';
import { TermsAgreementCheckboxWrapper } from './styles';

const schema = yup.object().shape({
    firstName: yup.string().required('First name is required.'),
    lastName: yup.string().required('Last name is required.'),
    username: yup.string().email('Email must be valid.').required('Email is required.'),
    password: yup.string().required('Password is required.'),
    confirmPassword: yup.string().required('Password is required.'),
});

interface State {
    password: string;
    confirmPassword: string;
    showPassword: boolean;
}

interface Props {
    userProfile: UserProfile;
}

export const SignUpForm = ({ userProfile }: Props) => {
    const navigate = useNavigate();
    const { errorToast } = useToast();
    const { dispatchUser } = useExerUser();
    const { httpClient, httpService } = useHttpContext();
    const { register, handleSubmit, formState, setError } = useForm({
        resolver: yupResolver(schema),
    });
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState<State>({
        password: '',
        confirmPassword: '',
        showPassword: false,
    });
    const [firstName, setFirstName] = useState(userProfile.firstName);
    const [lastName, setLastName] = useState(userProfile.lastName);
    const [userName, setUserName] = useState(userProfile.email);
    const [hasAgreed, setHasAgreed] = useState(false);

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSignUp = async (formData) => {
        setLoading(true);
        userProfile.firstName = formData.firstName;
        userProfile.lastName = formData.lastName;

        if (formData.password !== formData.confirmPassword) {
            setLoading(false);
            setError('confirmPassword', {
                type: 'manual',
                message: 'Password does not match.',
            });
            return;
        }

        // Clear out params not being saved in Cognito
        const param = {
            ...formData,
            attributes: {
                email: formData.email,
            },
        };

        try {
            const res = await Auth.signUp(param);

            // Sign in to get token
            const signInResponse = await Auth.signIn(formData.username, formData.password);
            const withoutRoles = signInResponse.getSignInUserSession();
            assignUserProps(withoutRoles, httpClient, dispatchUser);

            const updates = {
                userId: res.userSub,
                firstName: userProfile.firstName,
                lastName: userProfile.lastName,
            };
            await httpService.completeSignUp(userProfile.id, updates);

            //Force refresh sign in session to get roles and get user profile
            const currentUserResponse = await Auth.currentAuthenticatedUser({ bypassCache: true });
            const sius = currentUserResponse.getSignInUserSession();
            assignUserProps(sius, httpClient, dispatchUser);
            await refreshUserProfile(httpService, dispatchUser);

            setLoading(false);
            navigate(`${Paths.main}`, { replace: true });
        } catch (e) {
            errorToast(e.message);
            setLoading(false);
        }
    };

    const handleAgree = () => {
        setHasAgreed(!hasAgreed);
    };
    return (
        <>
            <AuthForm data-cy="login-form" onSubmit={handleSubmit(handleSignUp)} noValidate>
                <TextField
                    variant="outlined"
                    {...register('firstName', { value: userProfile.firstName })}
                    margin="normal"
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoComplete="firstName"
                    autoFocus
                    disabled
                    value={firstName}
                    defaultValue={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    error={!!formState.errors.firstName}
                    helperText={formState.errors.firstName && (formState.errors.firstName.message as string)}
                />
                <TextField
                    variant="outlined"
                    {...register('lastName', { value: userProfile.lastName })}
                    margin="normal"
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lastName"
                    autoFocus
                    disabled
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    error={!!formState.errors.lastName}
                    helperText={formState.errors.lastName && (formState.errors.lastName.message as string)}
                />
                <TextField
                    variant="outlined"
                    {...register('username', { value: userProfile.email })}
                    margin="normal"
                    fullWidth
                    id="username"
                    label="Email"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    disabled
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    error={!!formState.errors.username}
                    helperText={formState.errors.username && (formState.errors.username.message as string)}
                />
                <AuthPasswordControl variant="outlined" error={!!formState.errors.password} fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">Create a Password</InputLabel>
                    <OutlinedInput
                        {...register('password')}
                        placeholder="Create a Password"
                        required
                        id="password"
                        type={values.showPassword ? 'text' : 'password'}
                        name="password"
                        autoComplete="password"
                        label="Create a Password"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText id="password">
                        {formState.errors.password && (formState.errors.password.message as string)}
                    </FormHelperText>
                </AuthPasswordControl>
                <AuthPasswordControl variant="outlined" error={!!formState.errors.confirmPassword} fullWidth>
                    <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                    <OutlinedInput
                        {...register('confirmPassword')}
                        placeholder="Confirm password"
                        required
                        id="confirmPassword"
                        type={values.showPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        autoComplete="confirmPassword"
                        label="Confirm password"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <EyeOpenIcon /> : <EyeClosedIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText id="confirmPassword">
                        {formState.errors.confirmPassword && (formState.errors.confirmPassword.message as string)}
                    </FormHelperText>
                </AuthPasswordControl>
                <TermsAgreementCheckboxWrapper>
                    <FormControlLabel
                        control={<Checkbox value={'agree'} checked={hasAgreed} name={'agree'} onChange={handleAgree} />}
                        label={
                            <>
                                I agree to the Exer{' '}
                                <a href="https://www.exer.ai/terms-of-use" target="_blank" rel="noreferrer">
                                    Terms of Service
                                </a>{' '}
                                and{' '}
                                <a href="https://www.exer.ai/privacy-policy" target="_blank" rel="noreferrer">
                                    Privacy Policy.
                                </a>
                            </>
                        }
                    />
                </TermsAgreementCheckboxWrapper>
                <Box my={3}>
                    <LoadingButton loading={loading} variant="primary" fullWidth type="submit" disabled={!hasAgreed}>
                        Sign Up
                    </LoadingButton>
                </Box>
            </AuthForm>
        </>
    );
};
